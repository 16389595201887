import gql from 'graphql-tag'

export const GET_PLANS = gql`
query plans($first: Int, $skip:Int, $filters: PlanWhereInput, $orderByFilter: PlanOrderByInput){
  plans(first: $first, skip: $skip, where: $filters, orderBy: $orderByFilter){
    id,
    name,
    points,
    validity,
    price,
    isDisabled,
    type,
    renewAmount
  }
}
`;

export const PLANS_CONNECTION = gql`
query plansConnection($filters: PlanWhereInput, $orderByFilter: PlanOrderByInput){
  plansConnection(where: $filters, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;
