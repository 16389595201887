import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col, Table, Pagination } from 'antd'
import moment from 'moment'
import { GET_PAYOUTS, PAYOUTS_CONNECTION } from '../../commission/graphql/Queries'
import Meta from '../../../components/Meta'
import titleCase from '../../../components/titleCase'
import '../restaurants.css'

function Commission(props) {
  const { match: { params: { restaurantId } } } = props
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState()
  // const [filters, setFilters] = useState({ restaurant: { id: restaurantId } })

  const { data: payoutData, error: payError, loading: payLoad, fetchMore } = useQuery(GET_PAYOUTS, { variables: { filters: { restaurant: { id: restaurantId } }, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: payoutDataCount, loading: countLoading, error: payoutDataCountError } = useQuery(PAYOUTS_CONNECTION, { variables: { filters: { restaurant: { id: restaurantId } }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !payoutDataCountError ? (payoutDataCount && payoutDataCount.payoutsConnection) ? payoutDataCount.payoutsConnection.aggregate.count : 1 : 1

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  const column = [
    {
      title: 'Amount (₹)',
      dataIndex: 'amount',
      width: '15%',
      render: (amount) => amount ? Math.round(amount) : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      width: '23%',
      render: (time) => time ? moment(time).format('DD/MM/YYYY, hh:mm A') : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      width: '15%',
      render: (paymentType) => paymentType ? titleCase(paymentType.replace('_', ' ')) : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Paid By',
      dataIndex: 'paidBy',
      width: '15%',
      render: (paidBy) => paidBy ? paidBy : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Received By',
      dataIndex: 'receivedBy',
      width: '15%',
      render: (receivedBy) => receivedBy ? receivedBy : '-',
      sorter: (a, b) => a.age - b.age
    }
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!payError && payoutData && payoutData.payouts) {
    postTableData = payoutData.payouts.map((pay, key) => (
      {
        key: key.toString(),
        restaurant: pay.restaurant,
        amount: pay.amount,
        receivedBy: pay.receivedBy,
        paidBy: pay.paidBy,
        createdAt: pay.createdAt,
        paymentType: pay.paymentTypes

      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > payoutData.payouts.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { payouts } = fetchMoreResult
          setTableLoading(false)
          return payouts.length ? { payouts: [...payouts] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Commission" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={payLoad || countLoading || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </Col>
    </Row>
  )
}

export default withRouter(Commission)
