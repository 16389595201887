import React, { Component, } from 'react'
import { Modal, Form, } from 'antd'
import ImportUserForm from './ImportUserForm'

const CollectionCreateForm = Form.create({ name: 'import_customer' })(

  class extends Component {
    render() {
      const { visible,/* isSubmit*/ onCancel, onCreate, form, okStatus, setOkStatus, refetch } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`Import Customers`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={null}
          closable={okStatus}
        >
          <ImportUserForm
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            setOkStatus={setOkStatus}
            refetch={refetch}
          />
        </Modal>
      )
    }
  }
)

export default class ImportUserModal extends Component {
  render() {
    const { shoModal, isSubmit, handleCancel, handleCreate, saveFormRef, okStatus, setOkStatus, refetch } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={shoModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        okStatus={okStatus}
        setOkStatus={setOkStatus}
        refetch={refetch}
      />
    )
  }
}
