import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddPlanForm from './AddPlanForm'

const CollectionCreateForm = Form.create({ name: 'create_plan' })(

  class extends Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, planEditableData } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${planEditableData ? "Edit Plan Details" : "Add New Plan"}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button key="back" onClick={onCancel}>Cancel</Button>,
            <Button key="submit" type="primary" loading={isSubmit} onClick={onCreate}>
              {planEditableData ? "Save" : "Add Plan"}
            </Button>
          ]}
        >
          <AddPlanForm
            {...planEditableData}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
          />
        </Modal>
      )
    }
  }
)

export default class AddPlanModal extends Component {
  render() {
    const { shoModal, isSubmit, handleCancel, handleCreate, saveFormRef, planEditableData } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={shoModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        planEditableData={planEditableData}
      />
    )
  }
}
