import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Input, Typography, Select } from 'antd'
import { GET_RESTAURANTS_LIST } from '../../restaurants/graphql/Queries'

const { Text } = Typography
const { Option } = Select

export default function (props) {
  const { data: resData } = useQuery(GET_RESTAURANTS_LIST, { fetchPolicy: 'network-only' })
  const { getFieldDecorator, restaurantId, receivedBy, paidBy, amount, setDueAmount, dueAmount, paymentType } = props
  const paymentTypes = [
    { key: "Cheque", value: "CHEQUE" },
    { key: "Cash", value: "CASH" },
    { key: "Online Transfer", value: "ONLINE_TRANSFER" },
    { key: "Adjustment", value: "ADJUSTMENT" }
  ]
  let restaurants = []

  if (resData && resData.restaurants) {
    restaurants = resData.restaurants.map((res, key) => (
      {
        key: key.toString(),
        id: res.id,
        name: res.name,
        address: res.address,
        dueAmount: res.dueAmount
      }
    ))
  }

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="Restaurant">
        {getFieldDecorator('restaurantId', {
          rules: [{ required: true, message: 'Please select Restaurant' }],
          initialValue: restaurantId
        })(
          <Select >
            {restaurants ? (restaurants.map((res, i) => (<Option onClick={() => setDueAmount(res.dueAmount)} key={i} value={res.id}>{`${res.name}, ${res.address}`}</Option>))) : 'Loading..'}
          </Select>
        )}
      </Form.Item>
      <Form.Item label="Paid By">
        {getFieldDecorator('paidBy',
          {
            initialValue: paidBy
          })(<Input />)}
      </Form.Item>
      <Form.Item label="Received By">
        {getFieldDecorator('receivedBy',
          {
            initialValue: receivedBy
          })(<Input />)}
      </Form.Item>
      <Form.Item label="Payment Type">
        {getFieldDecorator('paymentType',
          {
            rules: [{ required: true, message: "Please select Payment type" }],
            initialValue: paymentType
          })(
            <Select>
              {paymentTypes.map((type, i) => (<Option key={i} value={type.value}>{type.key}</Option>))}
            </Select>
          )}
      </Form.Item>
      <Form.Item label="Amount" >
        {getFieldDecorator('amount',
          {
            rules: [{ required: true, message: "Please enter amount" },],
            initialValue: amount
          })(<Input type='number' max={dueAmount} min={0} disabled={dueAmount === 0} />)}
      </Form.Item>
      {
        dueAmount !== null && dueAmount !== undefined && dueAmount.toString() ?
          <Text type='danger'>{`Due Amount: ₹${Math.round(dueAmount)}`}</Text> : null
      }
    </Form>
  )
}
