import React, { useState, useRef, useContext } from 'react'
import { Row, Col, Table, Pagination, Icon, Tooltip, Popconfirm, Switch, notification, Button, Input } from 'antd'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { AppContext } from '../../../AppContext'
import client from '../../../apollo'
import { GET_USERS, USER_CONNECTIONS } from '../../customers/graphql/Queries'
import { UPDATE_USER, CREATE_USER } from '../../customers/graphql/Mutations'
import AddStaffModal from './AddStaffModal'
import Meta from '../../../components/Meta'
import '../restaurants.css'

function Staff(props) {
  const { state } = useContext(AppContext)
  const { match: { params: { restaurantId } } } = props
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [userEditableData, setUserEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState()
  const [filters, setFilters] = useState({ OR: [{ role: "RESTAURANT_STAFF" }, { role: "RESTAURANT_OWNER" }], restaurant: { id: restaurantId } })

  const { data: customerData, error: customerError, loading: customerLoad, fetchMore } = useQuery(GET_USERS, { variables: { filters, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: customerDataCount, error: customerDataCountError } = useQuery(USER_CONNECTIONS, { variables: { filters, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !customerDataCountError ? (customerDataCount && customerDataCount.usersConnection) ? customerDataCount.usersConnection.aggregate.count : 1 : 1

  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setUserEditableData("")
  }

  function handleRequestFail() {
    setTableLoading(false)
    openNotification("error", "Something Went Wrong")
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    const restaurantMembers = ['RESTAURANT_OWNER', 'RESTAURANT_STAFF']

    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const { firstName, lastName, phone, restaurantId, role } = values
      const data = {
        firstName,
        lastName,
        phone,
        role: role
      }
      if (restaurantId && restaurantMembers.includes(role)) data.restaurant = { connect: { id: restaurantId } }
      if (userEditableData) {
        try {
          client.mutate({
            mutation: UPDATE_USER,
            variables: {
              data,
              id: userEditableData.id
            },
            refetchQueries: [{
              query: GET_USERS,
              variables: { filters, orderByFilter, first: 10 }
            }]
          })
            .then(res => {
              if (res) {
                openNotification('success', 'User updated successfully!');
                setUserEditableData("")
                setShowModal(false)
                setSubmit(false)
                form.resetFields()
              }
            })
            .catch(e => {
              setSubmit(false)
              console.log(e)
            })
        } catch (e) {
          console.log(e)
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
        }
      } else {
        try {
          data.isActive = true
          client.mutate({
            mutation: CREATE_USER,

            variables: {
              data
            },
            refetchQueries: [{
              query: GET_USERS,
              variables: { filters, orderByFilter, first: 10 }
            }]
          }).then(res => {
            if (res) {
              openNotification('success', 'User added Successfully!');
              setUserEditableData("")
              setShowModal(false)
              setSubmit(false)
              form.resetFields()
            }
          }).catch(e => {
            setSubmit(false)
            console.log(e)
          })
        } catch (e) {
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
          console.log(e)
        }
      }
    })
  }
  async function handleNotification(userId, notification) {
    await client.mutate({
      mutation: UPDATE_USER,
      variables: { data: { notification: !notification }, id: userId },
      refetchQueries: [{
        query: GET_USERS,
        variables: { filters, orderByFilter, first: 10 }
      }]
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", `Notification ${notification ? 'Deactivated' : 'Activated'}!`)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        openNotification("error", "Something went wrong!")
        handleRequestFail()
      })
  }

  async function handleActive(userId, status) {
    await client.mutate({
      mutation: UPDATE_USER,
      variables: { data: { isActive: !status }, id: userId },
      refetchQueries: [{
        query: GET_USERS,
        variables: { filters, orderByFilter, first: 10 }
      }]
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", `User ${status ? 'Deactivated' : 'Activated'}!`)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        openNotification("error", "Something went wrong!")
        handleRequestFail()
      })
  }

  function handleEditButton(data) {
    setUserEditableData(data)
    setShowModal(true)
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={input => input && input.focus()}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, tableFilters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
    if (tableFilters && tableFilters.role) {
      let tempFilters = {}
      tempFilters["role_in"] = tableFilters.role && tableFilters.role.length > 0 ? tableFilters.role : ['ADMIN', 'IKOVERK_STAFF', 'RESTAURANT_OWNER', 'RESTAURANT_STAFF']
      setFilters({ ...filters, ...tempFilters })
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setCurrentPageNumber(1)
    confirm()
    let tempFilters = {}
    if (dataIndex === 'firstName') {
      tempFilters["firstName_contains"] = selectedKeys[0]
    } else if (dataIndex === 'lastName') {
      tempFilters["lastName_contains"] = selectedKeys[0]
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = selectedKeys[0]
    } else if (dataIndex === 'phone') {
      tempFilters["phone_contains"] = selectedKeys[0]
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    let tempFilters = {}
    if (dataIndex === 'firstName') {
      tempFilters["firstName_contains"] = ""
    } else if (dataIndex === 'lastName') {
      tempFilters["lastName_contains"] = ""
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = ""
    } else if (dataIndex === 'phone') {
      tempFilters["phone_contains"] = ""
    }
    setFilters({ ...filters, ...tempFilters })
  }

  const getRole = {
    'RESTAURANT_STAFF': 'Restaurant Staff',
    'RESTAURANT_OWNER': 'Restaurant Owner',
  }

  const getRoles = [
    { value: 'RESTAURANT_STAFF', text: 'Restaurant Staff' },
    { value: 'RESTAURANT_OWNER', text: 'Restaurant Owner' },
  ]

  const column = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: '17%',
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: '17%',
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'User Type',
      dataIndex: 'role',
      width: '17%',
      render: (role) => role ? getRole[role] : '-',
      filters: getRoles,
    },
    {
      title: 'Contact',
      dataIndex: 'phone',
      width: '17%',
      render: (phone) => phone ? phone : '-',
      ...getColumnSearchProps('phone')
    },
    {
      align: 'center',
      title: 'Send Notifications',
      dataIndex: 'notification',
      width: '17%',
      render: (text, record) => {
        return (['RESTAURANT_STAFF', 'RESTAURANT_OWNER'].includes(record.role) ?
          <div className="action-icons">
            <Popconfirm
              title={`Are you sure you want to ${record.notification ? 'deactivate' : 'activate'} notifications for this user?`}
              onConfirm={() => handleNotification(record.id, record.notification)}
            >
              <Tooltip title={`${record.notification ? 'Deactivate' : 'Activate'}`}>
                <Switch size='small' defaultChecked={record.notification} checked={record.notification} />
              </Tooltip>
            </Popconfirm>
          </div> : '-'
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '21%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon type="edit" theme="twoTone" onClick={() => handleEditButton(record)} />
            </Tooltip>
            &emsp;
            {
              record.id === state.currentUser.id ?
                <Switch size='small' defaultChecked={record.isActive} disabled={true} checked={record.isActive} />
                :
                <Popconfirm
                  title={`Are you sure you want to ${record.isActive ? 'deactivate' : 'activate'} this user?`}
                  onConfirm={() => handleActive(record.id, record.isActive)}
                >
                  <Tooltip title={`${record.isActive ? 'Deactivate' : 'Activate'}`}>
                    <Switch size='small' defaultChecked={record.isActive} checked={record.isActive}/*onChange={(e)=>handleActive(record.id, e)}*/ />
                  </Tooltip>
                </Popconfirm>
            }
          </div>
        )
      }
    }
  ]
  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!customerError && customerData && customerData.users) {
    postTableData = customerData.users.map((user, key) => (
      {
        key: key.toString(),
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        restaurant: user.restaurant ? user.restaurant : null,
        isActive: user.isActive,
        phone: user.phone,
        role: user.role,
        restaurantId: user.restaurant && user.restaurant.id ? user.restaurant.id : null,
        notification: user.notification
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > customerData.users.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { users } = fetchMoreResult
          setTableLoading(false)
          return users.length ? { users: [...users] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="System Users" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={customerLoad || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination current={currentPageNumber} total={totalCount} {...paginationProps} />
        <AddStaffModal
          saveFormRef={saveFormRef}
          shoModal={showModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleCreate}
          userEditableData={userEditableData}
        />
      </Col>
    </Row>
  )
}

export default withRouter(Staff)
