import React, { useEffect, useContext } from 'react'
import { notification } from 'antd'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { LOGOUT } from '../modules/auth/graphql/Queries'
import client from '../apollo'
import { AppContext } from '../AppContext'

const cookies = new Cookies();

export default withRouter(function (props) {
  const { dispatch } = useContext(AppContext)
  useEffect(() => {
    client.mutate({ mutation: LOGOUT })
      .then(response => {
        cookies.remove('connect.sid', { path: '/' })
        cookies.remove('token', { path: '/' })
        dispatch({ type: 'CURRENT_USER', data: {} })
        notification['success']({ message: 'Logout Successfully!', duration: 3 })
        setTimeout(() => {
          props.history.push('/login')
          window.location.reload()
        }, 3000);
      }).catch(error => {
        console.log({ error })
        notification['error']({ message: 'Unable to logout', duration: 3 })
      })
  }, [props.history, dispatch])
  return (
    <div>
    </div>
  )
})
