import React from 'react'
import { Form, Input, InputNumber } from 'antd'

export default function (props) {
  const { getFieldDecorator, firstName, lastName, phone, id, userSubscriptionId } = props

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="ID" style={{ display: 'none' }}>
        {getFieldDecorator('userId', {
          initialValue: id
        })(
          <Input disabled={true} />
        )}
      </Form.Item>
      <Form.Item label="userSubscriptionId" style={{ display: 'none' }}>
        {getFieldDecorator('userSubscriptionId', {
          initialValue: userSubscriptionId
        })(
          <Input disabled={true} />
        )}
      </Form.Item>
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          initialValue: firstName
        })(
          <Input disabled={true} />
        )}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName',
          {
            initialValue: lastName
          })(<Input disabled={true} />)}
      </Form.Item>
      <Form.Item label="Contact Number">
        {getFieldDecorator('phone',
          {
            initialValue: phone
          })(<Input type="number" disabled={true} />)}
      </Form.Item>
      <Form.Item label="Amount">
        {getFieldDecorator('amount',
          {
            rules: [{ required: true, message: "Please enter amount to topup." },
            { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid amount' }],
          })(<InputNumber autoFocus={true} precision={0} type="number" style={{ width: '100%' }} />)}
      </Form.Item>
    </Form>
  )
}
