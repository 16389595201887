import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddPaymentForm from './AddPaymentForm'

const CollectionCreateForm = Form.create({ name: 'create_payment' })(
  class extends Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, userEditableData, setDueAmount, dueAmount } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${userEditableData ? "Edit Details" : "Add Payment"}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button key="back" onClick={onCancel}>Cancel</Button>,
            <Button key="submit" type="primary" loading={isSubmit} onClick={onCreate} disabled={dueAmount === 0}>
              {userEditableData ? "Save" : "Add Payment"}
            </Button>
          ]}
        >
          <AddPaymentForm
            {...userEditableData}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
            setDueAmount={setDueAmount}
            dueAmount={dueAmount}
          />
        </Modal>
      )
    }
  }
)

export default class AddPaymentModal extends Component {
  render() {
    const { shoModal, isSubmit, handleCancel, handleCreate, saveFormRef, userEditableData, setDueAmount, dueAmount } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={shoModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        userEditableData={userEditableData}
        setDueAmount={setDueAmount}
        dueAmount={dueAmount}
      />
    )
  }
}
