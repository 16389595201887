import React, { useState, useEffect } from 'react'
import { Form, Upload, Icon, Button, Typography, message, Row, Col, notification, Descriptions, Table } from 'antd'
import { CSVLink } from "react-csv";
import client from '../../../apollo';
import { IMPORT_USERS } from '../graphql/Mutations'
import moment from 'moment';
const { Text, Title } = Typography;

export default function (props) {
  const { getFieldDecorator, setOkStatus, refetch, showModal } = props
  const [uploadStatus, setUploadStatus] = useState(false)
  const [fileList, setFileList] = useState([])
  const [errorData, setErrorData] = useState(null)

  const headers = [
    { label: 'phone', key: 'data.phone' },
    { label: 'first_name', key: 'data.first_name' },
    { label: 'last_name', key: 'data.last_name' },
    { label: 'email', key: 'data.email' },
    { label: 'plan', key: 'data.plan' },
    { label: 'validity', key: 'data.validity' },
    { label: 'message', key: 'data.message' },
  ];

  const columns = [
    {
      title: 'Phone',
      dataIndex: 'data',
      width: '30%',
      render: data => data.phone ? data.phone : '-',
    },
    {
      title: 'Error',
      dataIndex: 'data',
      width: '70%',
      render: data => data.message ? data.message : '-',
    }
  ]
  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  useEffect(() => {
    if (!showModal) {
      setFileList([])
      setErrorData(null)
    }
  }, [showModal])

  const handleUpload = () => {
    setUploadStatus(true)
    setOkStatus(false)
    client.mutate({
      mutation: IMPORT_USERS,
      variables: { file: fileList[0] },
    }).then(res => {
      if (res && res.data && res.data.importUsers && res.data.importUsers && res.data.importUsers.importUser && res.data.importUsers.importUser === 'success') {
        openNotification('success', 'Customers imported successfully!')
        setFileList([])
        refetch()
      } else if (res && res.data && res.data.importUsers && res.data.importUsers) {
        openNotification('error', `${res.data.importUsers.length} users failed to import`)
        setErrorData(res.data.importUsers)
      }
      setUploadStatus(false)
      setOkStatus(true)
    }).catch(e => {
      console.log(e)
      setUploadStatus(false)
      setOkStatus(true)
      // openNotification('error', 'Something went wrong')
    })
  }

  const uploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList)
      setErrorData(null)
    },
    beforeUpload: file => beforeUploadTest(file),
    fileList,
  };

  function beforeUploadTest(file) {
    const isValid = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type);
    if (!isValid) {
      message.error('You can only upload csv file!');
    } else if (fileList.length > 0) {
      message.error('You can only upload one file!');

    } else {
      setFileList([...fileList, file])
    }
    // const isLt2M = file.size / 1024 / 1024 < 10;
    // if (!isLt2M) {
    //   message.error('File size must smaller than 10MB!');
    // }
    return false;
  }

  return (<React.Fragment>
    <Form colon={false} layout="vertical" className="add-plan-form" encType={'multipart/form-data'}>
      <Row>
        <Col span={16}>
          <Form.Item >
            {getFieldDecorator('upload', {
              valuePropName: 'upload',
            })(
              <Upload
                name="upload"
                {...uploadProps}
                className='customer-fileupload'
                accept='.csv'
              // accept='.csv,.xls,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              // disabled={fileList.length > 0}
              >
                <Button disabled={fileList.length > 0} >
                  <Icon type="upload" /> Upload File
              </Button>
              </Upload>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            onClick={() => handleUpload()}
            disabled={fileList.length === 0}
            loading={uploadStatus}
          >
            {uploadStatus ? 'Processing' : 'Start Upload'}
          </Button>
        </Col>
      </Row>
    </Form>
    <Text type="secondary">
      Note: Use the <a href='https://ikoverk-dev.nyc3.digitaloceanspaces.com/ikoverk-sample-file.csv' ><Text code underline strong style={{ cursor: 'pointer' }}>Sample file</Text></a> to fill the data. Upload the file after verifying the format of your file with sample file.
      </Text>
    {errorData ?
      <>
        <Row gutter={24} type="flex" >
          <Col span={24}>
            <div className="title-wrapper">
              <Title level={4}>Error occured</Title>
              <CSVLink data={errorData} headers={headers} filename={`ikoverk-error-${moment().format('DD-MM-YYYY')}.csv`}><Button type="primary" >Download CSV</Button></CSVLink>
            </div>
          </Col>
        </Row>
        <Table
          scroll={{ x: true }}
          bordered
          dataSource={errorData}
          columns={columns}
          pagination={false}
        />
      </> :
      <Descriptions title="File Format" bordered size='small' column={1}>
        <Descriptions.Item label="Required Fields" >
          - Phone
      <br />
          - First Name
      <br />
          - Last Name
        </Descriptions.Item>
        <Descriptions.Item label="Optional Fields" >
          - Email
      <br />
          - Plan (case sensitive)
      <br />
          - Plan Validity (dd-mm-yyyy)
         </Descriptions.Item>
        <Descriptions.Item label="Additional Info" >
          - File extension must be .csv
          {/* - File extension must be .csv / .xls /.xlsx */}
          <br />
          - If plan validity is left blank then default plan validity will be used.
        </Descriptions.Item>
      </Descriptions>}
  </React.Fragment >
  )
}
