import React, { useContext } from 'react'
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd'
import { NavLink } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { AppContext } from '../../AppContext'

const { Header } = Layout

export default function (props) {
  const { state } = useContext(AppContext)
  const { collapsed, toggle, isIkoverkMember } = props

  const menu = (
    <Menu>
      <Menu.Item>
        <NavLink to="/logout" className="nav-text">
          Logout
        </NavLink>
      </Menu.Item>
    </Menu>
  )
  return (
    <Header className={`header ${isIkoverkMember ? "" : "restaurant-member"}`}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {
            isIkoverkMember ?
              <Icon
                className="trigger"
                type={collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={toggle}
              />
              :
              <NavLink to="/" className="nav-text">
                <div className="logo">
                  <img src={logo} alt="iKoverk" title="iKoverk" />
                </div>
              </NavLink>
          }
        </div>
        <div style={{ display: 'flex' }}>
          {
            state.currentUser.firstName &&
            <div className="menu-divider-space user">
              <div>
                <Avatar>{state.currentUser.firstName.charAt(0)}</Avatar>
                <Dropdown overlay={menu}>
                  {/* eslint-disable-next-line */}
                  <a className="ant-dropdown-link" style={{ paddingLeft: 10, divor: 'inherit' }}>
                    {state.currentUser.firstName} {state.currentUser.lastName && state.currentUser.lastName} <Icon type="down" />
                  </a>
                </Dropdown>
              </div>
            </div>
          }
        </div>
      </div>
    </Header>
  )
}
