import gql from 'graphql-tag'

export const CHECK_USER = gql`
query checkUser($phone: String){
  checkUser(where:{ phone: $phone, role_not: USER })
}
`
export const LOGIN = gql`
mutation login($phone: String!, $password: String!) {
  login(data: { phone: $phone, password: $password })
    @rest(path: "login", method: "POST", bodyKey: "data") {
      message
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout @rest(path: "logout", method: "GET") {
      message
    }
  }
`