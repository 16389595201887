import gql from 'graphql-tag';

export const CREATE_RESTAURANT = gql`
mutation createRestaurant($data: RestaurantCreateInput!){
  createRestaurant(data: $data){
    id
    name
    address
    commission
    discount
    logo
    mapUrl
    city
    state
    pincode
    capacity
    isDisabled
    restaurantTimings
}
}
`;

export const UPDATE_RESTAURANT = gql`
mutation updateRestaurant($data: RestaurantUpdateInput! $id: ID!){
  updateRestaurant(data: $data where: {id: $id}){
    id
    name
    address
    commission
    discount
    logo
    state
    city
    pincode
    capacity
    mapUrl
    isDisabled
    restaurantTimings
  }
}
`;