import React, { Component } from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import Routes from './Routes'
import './App.css'

// Follow all todo in admin as well

const firebaseConfig = {
  apiKey: "AIzaSyAYVUDVcM0en9nLnQwhSozvxRACu3tCWaE",
  authDomain: "ikoverk-pass.firebaseapp.com",
  databaseURL: "https://ikoverk-pass.firebaseio.com",
  projectId: "ikoverk-pass",
  storageBucket: "ikoverk-pass.appspot.com",
  messagingSenderId: "822051804580",
  appId: "1:822051804580:web:f79641d8726667558c9d31",
  measurementId: "G-FVBKRGERTM"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default class App extends Component {
  render() {
    return (
      <div>
        <Routes />
      </div>
    )
  }
}
