import React from 'react'
import { Route } from 'react-router-dom'
import Cookies from "universal-cookie";
import Login from './'

const cookies = new Cookies();

function GuardedRoute(props) {
  const authorizationToken = cookies.get("token");
  const isAuthenticated = authorizationToken ? true : false
  const { component: Component, path } = props
  return (
    <Route path={path} render={(props) => {
      if (isAuthenticated !== undefined && !isAuthenticated) return <Login />
      return <Component {...props} />
    }} />
  )
}

export default GuardedRoute;
