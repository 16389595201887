import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, from, split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { ErrorLink } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from 'apollo-utilities'
import { createHttpLink } from 'apollo-link-http'
import Cookies from 'universal-cookie'
import { RestLink } from 'apollo-link-rest'
import { notification } from 'antd'
import getErrorMsg from './common/ErrorMessages'
import history from './history'

export const cacheData = new InMemoryCache()

function openNotification(type, message) {
  notification[type]({
    message,
    duration: 3
  })
}

function stripTypeNames(obj, propToDelete) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypeNames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }
  return obj
}

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypeNames(operation.variables, "__typename")
  }
  return forward ? forward(operation) : null
});

const errorLink = new ErrorLink(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {

    openNotification('error', getErrorMsg(graphQLErrors[0].message))
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Not Authorised!') {
        history.push('/logout')
      }
      return console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (response) {
    response.errors.map(({ message, locations, path }) => {
      if (message === 'Not Authorised!') {
        history.push('/logout')
      }
      return console.log(
        `[Response error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    if (networkError.message === 'Not Authorised!') {
      history.push('/logout')
    }
    openNotification('error', getErrorMsg(networkError.message))
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = createHttpLink({
  credentials: "include",
  uri: `${process.env.REACT_APP_SERVER_GRAPH_URL}graphql`
});

const cookies = new Cookies();

const authMiddleware = new ApolloLink((operation, forward) => {
  const authorizationToken = cookies.get("token");
  operation.setContext({
    headers: { authorization: authorizationToken ? `Bearer ${authorizationToken}` : null }
  });
  return forward(operation);
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SERVER_SOCKET_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    async connectionParams() {
      const authorizationToken = cookies.get("token");
      return {
        authorization: authorizationToken
          ? `Bearer ${authorizationToken}`
          : null
      };
    }
  }
});

window.addEventListener("beforeunload", () => {
  wsLink.subscriptionClient.close();
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const restLink = new RestLink({ uri: process.env.REACT_APP_SERVER_GRAPH_URL, credentials: 'include' });

const uploadLink = createUploadLink({
  credentials: 'include',
  uri: `${process.env.REACT_APP_SERVER_GRAPH_URL}graphql`
})

const client = new ApolloClient({
  cache: cacheData,
  link: from([removeTypenameMiddleware, errorLink, restLink, authMiddleware, uploadLink, link]),
  name: "iKoverk"
});

export default client;
