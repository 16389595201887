import React, { Component } from 'react'
import { Modal, Button, Descriptions } from 'antd'
import moment from 'moment'

export default class ReverseDetailsModal extends Component {
  render() {
    const { onCancel, visible, editableData } = this.props
    const { customerName, restaurant, reverseTransactionData, amount, createdAt } = editableData
    return (
      <Modal
        visible={visible}
        maskClosable={true}
        title={`Reverse Payment Details`}
        onCancel={onCancel}
        footer={[
          <Button key="back" type="primary" onClick={onCancel}>OK</Button>,
        ]}
      >
        {reverseTransactionData &&
          <React.Fragment>
            <Descriptions bordered size='middle' column={1}>
              <Descriptions.Item label="Customer Name" >{customerName ? customerName : null}</Descriptions.Item>
              <Descriptions.Item label="Restaurant Name" >{restaurant ? restaurant : null}</Descriptions.Item>
              <Descriptions.Item label="Transction Time" >{createdAt ? moment(createdAt).format('DD/MM/YYYY, hh:mm A') : null}</Descriptions.Item>
              <Descriptions.Item label="Transaction Amount" >{amount ? Math.abs(amount) : null}</Descriptions.Item>
            </Descriptions>
            <br />
            <Descriptions bordered size='middle' column={1}>
              <Descriptions.Item label="Reverse Transaction" >{reverseTransactionData.createdAt ? moment(reverseTransactionData.createdAt).format('DD/MM/YYYY, hh:mm A') : null}</Descriptions.Item>
              <Descriptions.Item label="Reversed Amount" >{reverseTransactionData.amount ? Math.abs(reverseTransactionData.amount) : null}</Descriptions.Item>
              {
                reverseTransactionData.notes ?
                  <Descriptions.Item label="Note" >{reverseTransactionData.notes}</Descriptions.Item> : null
              }
            </Descriptions>
          </React.Fragment>
        }
      </Modal>
    )
  }
}
