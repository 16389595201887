export default function getErrorMsg(error) {
  let msg = 'Something went wrong!'
  switch (error) {
    case 'Failed to fetch':
      msg = 'Failed to connect to server.'
      break;
    case 'A unique constraint would be violated on Plan. Details: Field name = name':
      msg = 'Plan name already exits!'
      break;
    case 'plan name already exists!!!':
      msg = 'Plan name already exits!'
      break;
    case 'please enter valid funds!!!':
      msg = 'Please enter valid funds!'
      break;
    case 'A unique constraint would be violated on User. Details: Field name = email':
      msg = 'Email already exits!'
      break;
    case 'User email already exist!':
      msg = 'Email already exits!'
      break;
    case 'User phone no already exist!':
      msg = 'Phone number already exist!'
      break;
    case 'Format is not proper':
      msg = 'File Format is not proper!'
      break;
    default:
      msg = 'Something went wrong!'
      break;
  }
  return msg
}
