import gql from 'graphql-tag'

export const GET_SUBSCRIPTIONS = gql` 
query userSubscriptions($filters: UserSubscriptionWhereInput, $first:Int, $skip:Int, $orderByFilter: UserSubscriptionOrderByInput){
	userSubscriptions(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    id
    plan{
      id
      name
    }
    startTime
    endTime
    points
    status
    user{
      id
      firstName
      lastName
      phone
    }
  }
}`;

export const GET_TOPUPS = gql`
query topUps($filters: TopUpWhereInput, $first:Int, $skip:Int, $orderByFilter: TopUpOrderByInput){
  topUps(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    id
    topUpAmount
    user{
      id
      firstName
      lastName
      phone
    }
    payment{
      transactionId
      createdBy{
        role
        firstName
        lastName
      }
    }
    date
  }
}
`;