import React from 'react'
import { Layout, Menu, Icon } from 'antd'
import { NavLink } from 'react-router-dom'
import smallLogo from '../../assets/small-logo.png'
import logo from '../../assets/logo.png'

const { Sider } = Layout

export default function LayoutSider(props) {
  const { collapsed, pathname, role } = props
  const selectedItem = pathname.split('/')[1]

  const isIkoverkStaff = role === 'IKOVERK_STAFF'
  const isIkoverkAdmin = role === 'ADMIN'

  return (
    <Sider trigger={null} width={250} collapsible collapsed={collapsed} theme="light">
      <NavLink to="/" className="nav-text">
        <div className="logo">
          {collapsed ?
            <img className="small" src={smallLogo} alt="iKoverk" title="iKoverk" />
            :
            <img src={logo} alt="iKoverk" title="iKoverk" />
          }
        </div>
      </NavLink>
      <Menu mode="inline" selectable={false}>
        {/* <Menu.Item key="dashboard" className={`menu-list ${selectedItem === 'dashboard' ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to="/dashboard" className="nav-text">
              <Icon type="video-camera" />
              <span>Dashboard</span>
            </NavLink>
          </Menu.Item> */}
        {
          !isIkoverkStaff &&
          <Menu.Item key="plans" className={`menu-list ${selectedItem === 'plans' ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to="/plans" className="nav-text">
              <Icon type="gold" />
              <span>Plans</span>
            </NavLink>
          </Menu.Item>
        }
        <Menu.Item key="restaurants" className={`menu-list ${selectedItem === 'restaurants' ? 'ant-menu-item-selected' : ''}`}>
          <NavLink to="/restaurants" className="nav-text">
            <Icon type="shop" />
            <span>Restaurants</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="customers" className={`menu-list ${selectedItem === 'customers' ? 'ant-menu-item-selected' : ''}`}>
          <NavLink to="/customers" className="nav-text">
            <Icon type="team" />
            <span>Customers</span>
          </NavLink>
        </Menu.Item>
        {
          !isIkoverkStaff &&
          <Menu.Item key="system-users" className={`menu-list ${selectedItem === 'system-users' ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to="/system-users" className="nav-text">
              <Icon type="user" />
              <span>System Users</span>
            </NavLink>
          </Menu.Item>
        }
        <Menu.Item key="checkins" className={`menu-list ${selectedItem === 'checkins' ? 'ant-menu-item-selected' : ''}`}>
          <NavLink to="/checkins" className="nav-text">
            <Icon type="clock-circle" />
            <span>Check Ins</span>
          </NavLink>
        </Menu.Item>
        {
          !isIkoverkStaff &&
          <Menu.Item key="payment" className={`menu-list ${selectedItem === 'payment' ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to="/payment" className="nav-text">
              <Icon type="wallet" />
              <span>Payments</span>
            </NavLink>
          </Menu.Item>
        }
        {
          (isIkoverkStaff || isIkoverkAdmin) &&
          <Menu.Item key="exports" className={`menu-list ${selectedItem === 'exports' ? 'ant-menu-item-selected' : ''}`}>
            <NavLink to="/exports" className="nav-text">
              <Icon type="export" />
              <span>Exports</span>
            </NavLink>
          </Menu.Item>
        }
      </Menu>
    </Sider>
  )
}
