import React, { useState, useRef } from 'react'
import { Row, Col, Table, Typography, Button, notification, Pagination } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import client from '../../apollo'
import { GET_PAYOUTS, PAYOUTS_CONNECTION } from './graphql/Queries'
import { CREATE_PAYOUT } from './graphql/Mutations'
import AddPaymentModal from './components/AddPaymentModal'
import titleCase from '../../components/titleCase'
import Meta from '../../components/Meta'
import './commission.css'

const { Title } = Typography

export default function Commission(props) {
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [userEditableData, setUserEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("createdAt_DESC")
  // const [filters, setFilters] = useState({ deletedAt: null })
  const { data: payoutData, error: payError, loading: payLoad, fetchMore } = useQuery(GET_PAYOUTS, { variables: { filters: { deletedAt: null }, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: payoutDataCount, loading: countLoading, error: payoutDataCountError } = useQuery(PAYOUTS_CONNECTION, { variables: { filters: { deletedAt: null }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !payoutDataCountError ? (payoutDataCount && payoutDataCount.payoutsConnection) ? payoutDataCount.payoutsConnection.aggregate.count : 1 : 1
  const [dueAmount, setDueAmount] = useState(null)
  const saveFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setUserEditableData("")
    setDueAmount(null)
  }

  function handleShowModal(b) {
    setShowModal(b)
  }

  function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const { restaurantId, receivedBy, paidBy, amount, paymentType } = values
      if (amount > dueAmount) {
        openNotification('info', 'Amount cannot be greater than Due-Amount')
        return
      } else if (amount < 1) {
        openNotification('info', 'Enter valid amount')
        return
      }
      setSubmit(true)
      const data = {
        restaurant: { connect: { id: restaurantId } },
        amount: Math.round(amount),
        paidBy: paidBy,
        receivedBy: receivedBy,
        paymentTypes: paymentType
      }
      try {
        client.mutate({
          mutation: CREATE_PAYOUT,
          variables: { data },
          refetchQueries: [{
            query: GET_PAYOUTS,
            variables: { filters: { deletedAt: null }, orderByFilter, first: 10 },
            fetchPolicy: "network-only"
          }]
        })
          .then(res => {
            if (res.data.createPayout.id) {
              openNotification('success', 'Payment added Successfully!');
              setSubmit(false)
              setDueAmount(null)
              setUserEditableData("")
              setShowModal(false)
              form.resetFields()
            }
          })
          .catch(e => {
            console.log(e)
            setSubmit(false)
          })
      } catch (e) {
        console.log(e)
        openNotification('error', 'Something Went Wrong!')
        setSubmit(false)
      }
    })
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  const column = [
    {
      title: 'Restaurant Name',
      dataIndex: 'restaurant',
      width: '20%',
      render: (restaurant) => restaurant ? <NavLink to={`/restaurants/${restaurant.id}`} className="discussions-subject">{`${restaurant.name}, ${restaurant.address}`}</NavLink> : '-'
    },
    {
      title: 'Amount (₹)',
      dataIndex: 'amount',
      width: '15%',
      render: (amount) => amount ? Math.round(amount) : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      width: '20%',
      render: (time) => time ? moment(time).format('DD/MM/YYYY, hh:mm A') : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Payment Type',
      dataIndex: 'paymentType',
      width: '15%',
      render: (paymentType) => paymentType ? titleCase(paymentType.replace('_', ' ')) : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Paid By',
      dataIndex: 'paidBy',
      width: '15%',
      render: (paidBy) => paidBy ? paidBy : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Received By',
      dataIndex: 'receivedBy',
      width: '15%',
      render: (receivedBy) => receivedBy ? receivedBy : '-',
      sorter: (a, b) => a.age - b.age
    },
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!payError && payoutData && payoutData.payouts) {
    postTableData = payoutData.payouts.map((pay, key) => (
      {
        key: key.toString(),
        restaurant: pay.restaurant,
        amount: pay.amount,
        receivedBy: pay.receivedBy,
        paidBy: pay.paidBy,
        createdAt: pay.createdAt,
        paymentType: pay.paymentTypes
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > payoutData.payouts.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { payouts } = fetchMoreResult
          setTableLoading(false)
          return payouts.length ? { payouts: [...payouts] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Payments" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Payments</Title>
          <Button type="primary" size='large' onClick={() => handleShowModal(true)}>Add</Button>
        </div>
        <Table
          scroll={{ x: true }}
          bordered
          dataSource={postTableData}
          loading={payLoad || countLoading || tableLoading}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
        {
          showModal &&
          <AddPaymentModal
            saveFormRef={saveFormRef}
            shoModal={showModal}
            isSubmit={isSubmit}
            handleCancel={handleCancel}
            handleCreate={handleCreate}
            setDueAmount={setDueAmount}
            dueAmount={dueAmount}
            userEditableData={userEditableData}
          />
        }
      </Col>
    </Row>
  )
}
