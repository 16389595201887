import React, { Component } from 'react'
import { Modal, Form, Button } from 'antd'
import AddPlanForm from './AddUserForm'

const CollectionCreateForm = Form.create({ name: 'create_user' })(
  class extends Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, userEditableData } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`${userEditableData ? "Edit User Details" : "Add New User"}`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button key="back" onClick={onCancel}>Cancel</Button>,
            <Button key="submit" type="primary" loading={isSubmit} onClick={onCreate}>
              {userEditableData ? "Save" : "Add User"}
            </Button>
          ]}
        >
          <AddPlanForm
            {...userEditableData}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
          />
        </Modal>
      )
    }
  }
)

export default class AddPlanModal extends Component {
  render() {
    const { shoModal, isSubmit, handleCancel, handleCreate, saveFormRef, userEditableData } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={shoModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        userEditableData={userEditableData}
      />
    )
  }
}
