import gql from 'graphql-tag';

export const SET_PLAN = gql`mutation createUserSubscription($data: UserSubscriptionCreateInput!){
  createUserSubscription(data: $data){
    id
  }
}`;

export const UPDATE_USER = gql`
mutation updateUser($data : UserUpdateInput!, $id: ID!){
  updateUser(data: $data, where:{id: $id}){
    id
    planStatus
  }
}`;

export const CREATE_USER = gql`
mutation createUser($data: UserCreateWithoutPasswordInput){
  createUser(data: $data) {
    id
  }
}
`;
export const CREATE_TOPUP = gql`
  mutation createTopUp($data : TopUpCreateInput!){
    createTopUp(data: $data){
      id
    }
  }`;

export const IMPORT_USERS = gql`
    mutation importUsers($file:Upload!){
      importUsers(csv:$file)
    }
  `;

export const UPDATE_USER_SUBSCRIPTION = gql`
mutation updateUserSubscription($endTime:DateTime,$id:ID!){
  updateUserSubscription(data:{endTime:$endTime},where:{id:$id}){
    id
  }
}
`;