import React, { useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { GET_USER_SUBSCRIPTION, GET_USER_SUBSCRIPTION_CONNECTION } from '../graphql/Queries'
import Meta from '../../../components/Meta'
import '../customer.css'

function PlansHistory(props) {
  const orderByFilter = "startTime_DESC"
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const { match: { params: { customerId } } } = props
  const { data: historyData, error: historyError, loading: historyLoad, fetchMore } = useQuery(GET_USER_SUBSCRIPTION, { variables: { filters: { user: { id: customerId, } }, first: 10, orderByFilter }, fetchPolicy: 'network-only' })
  const { data: userSubscriptionCount, loading: countLoading, error: userSubscriptionCountError } = useQuery(GET_USER_SUBSCRIPTION_CONNECTION, { variables: { filters: { user: { id: customerId } }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !userSubscriptionCountError ? (userSubscriptionCount && userSubscriptionCount.userSubscriptionsConnection) ? userSubscriptionCount.userSubscriptionsConnection.aggregate.count : 1 : 1

  const column = [
    {
      title: 'Plan Name',
      dataIndex: 'planName',
      width: '25%',
      render: (planName) => planName ? planName : '-'
    },
    {
      title: 'Validity (Days)',
      dataIndex: 'validity',
      width: '15%',
      render: (validity) => validity ? validity : '-'
    },
    {
      title: 'Funds',
      dataIndex: 'points',
      width: '10%',
      render: (points) => points ? points : '-'
    },
    {
      title: 'Amount (₹)',
      dataIndex: 'price',
      width: '15%',
      render: (price) => price ? price : '-'
    },
    {
      title: 'Activated On',
      dataIndex: 'startTime',
      width: '17.5%',
      render: (startTime) => startTime ? moment(startTime).format('DD/MM/YYYY') : '-'
    }, {
      title: 'Expired On',
      dataIndex: 'endTime',
      width: '17.5%',
      render: (endTime) => endTime ? moment(endTime).format('DD/MM/YYYY') : '-'
    },
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!historyError && historyData && historyData.userSubscriptions) {
    postTableData = historyData.userSubscriptions.map((row, key) => (
      {
        key: key.toString(),
        planName: row.plans && row.plans.name ? row.plans.name : null,
        points: row.plans && row.plans.points ? row.plans.points : null,
        price: row.plans && row.plans.price ? row.plans.price : null,
        validity: row.plans && row.plans.validity ? row.plans.validity : null,
        startTime: row.startTime,
        endTime: row.endTime
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > historyData.userSubscriptions.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { userSubscriptions } = fetchMoreResult
          setTableLoading(false)
          return userSubscriptions.length ? { userSubscriptions: [...userSubscriptions] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Customers" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={historyLoad || countLoading || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </Col>
    </Row>
  )
}

export default withRouter(PlansHistory)
