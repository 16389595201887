import React, { useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { LOG_HISTORY_CONNECTION, LOG_HISTORY } from '../graphql/Queries'
import Meta from '../../../components/Meta'
import '../customer.css'

function PlansHistory(props) {
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const { match: { params: { customerId } } } = props
  const [orderByFilter, setOrderByFilter] = useState("createdAt_DESC")
  const { data: historyData, error: historyError, loading: historyLoad, fetchMore } = useQuery(LOG_HISTORY, { variables: { filters: { user: { id: customerId, } }, first: 10, orderByFilter }, fetchPolicy: 'network-only' })
  const { data: historyCount, loading: countLoading, error: historyCountError } = useQuery(LOG_HISTORY_CONNECTION, { variables: { filters: { user: { id: customerId } }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !historyCountError ? (historyCount && historyCount.logHistoriesConnection) ? historyCount.logHistoriesConnection.aggregate.count : 1 : 1

  const column = [
    {
      title: 'Activity',
      dataIndex: 'type',
      width: '20%',
      render: (type) => type ? type : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '15%',
      render: (name) => name ? `${name}` : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '20%',
      render: (description) => description ? `${description}` : '-'
    },
    {
      title: 'Amount',
      dataIndex: 'points',
      width: '10%',
      render: (points) => points ? `${points}` : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Type',
      dataIndex: 'creditDebit',
      width: '15%',
      render: (creditDebit) => creditDebit ? `${creditDebit}` : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt) => createdAt ? moment(createdAt).format('DD/MM/YYYY, hh:mm A') : '-',
      sorter: (a, b) => a.age - b.age
    },
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  let postTableData = []
  if (!historyError && historyData && historyData.logHistories) {
    postTableData = historyData.logHistories.map((row, key) => (
      {
        key: key.toString(),
        type: row.type,
        description: row.description,
        createdAt: row.createdAt,
        name: row.name,
        points: row.points,
        creditDebit: row.creditDebit
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > historyData.logHistories.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { logHistories } = fetchMoreResult
          setTableLoading(false)
          return logHistories.length ? { logHistories: [...logHistories] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Customers" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={historyLoad || countLoading || tableLoading}
          dataSource={postTableData}
          columns={columns}
          onChange={handleChange}
          pagination={false}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </Col>
    </Row>
  )
}

export default withRouter(PlansHistory)
