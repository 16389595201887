import React from 'react'
import { Spin, Icon } from 'antd'

const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
export default function () {
  return (
    <div className='loader-wrapper'>
      <Spin indicator={antIcon} />
    </div>
  )
}
