import gql from 'graphql-tag'

export const CREATE_PLAN = gql`
mutation createPlan($data: PlanCreateInput!){
  createPlan(data: $data){
    id
  }
}
`;

export const UPDATE_PLAN = gql`
mutation updatePlan($data: PlanUpdateInput!, $id: ID!){
  updatePlan(data: $data, where:{id: $id}){
    id
    name
    points
    validity
    price
    isDisabled
    type
  }
}
`;
