import gql from 'graphql-tag'

export const GET_PAYOUTS = gql`
query payouts($first:Int, $skip:Int, $filters: PayoutWhereInput, $orderByFilter: PayoutOrderByInput){
  payouts(first: $first, skip: $skip, where: $filters, orderBy: $orderByFilter){
    id
    restaurant{
      id
      name
      address
    }
    amount
    receivedBy
    paidBy
    createdAt
    paymentTypes
  }
}
`;


export const PAYOUTS_CONNECTION = gql`
query payoutsConnection($filters: PayoutWhereInput, $orderByFilter: PayoutOrderByInput){
  payoutsConnection(where: $filters, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;

export const GET_PLANS = gql`
query plans{
  plans{
    id,
    name,
    points,
    price
  }
}
`;
