import React, { Component } from 'react'
import { Modal, Form, Button, Popconfirm } from 'antd'
import TopupForm from './TopupForm'

const CollectionCreateForm = Form.create({ name: 'topup' })(

  class extends Component {
    render() {
      const { visible, isSubmit, onCancel, onCreate, form, userEditableData } = this.props
      const { getFieldDecorator } = form
      return (
        <Modal
          visible={visible}
          maskClosable={false}
          title={`Add Topup`}
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button key="back" onClick={onCancel}>Cancel</Button>,
            <Popconfirm
              title={`Are you sure you want to add topup for this customer?`}
              onConfirm={onCreate}
            >
              <Button key="submit" type="primary" loading={isSubmit}>Add</Button>
            </Popconfirm>
          ]}
        >
          <TopupForm
            {...userEditableData}
            showModal={visible}
            getFieldDecorator={getFieldDecorator}
          />
        </Modal>
      )
    }
  }
)

export default class TopupModal extends Component {
  render() {
    const { shoModal, isSubmit, handleCancel, handleCreate, saveFormRef, userEditableData } = this.props
    return (
      <CollectionCreateForm
        wrappedComponentRef={saveFormRef}
        visible={shoModal}
        isSubmit={isSubmit}
        onCancel={handleCancel}
        onCreate={handleCreate}
        userEditableData={userEditableData}
      />
    )
  }
}
