import gql from 'graphql-tag'

export const CHECKINS_CONNECTIONS = gql`
query checkInsConnections($filters: CheckInWhereInput, $orderByFilter: CheckInOrderByInput){
  checkInsConnection(where: $filters, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;

export const GET_CHECKINS = gql`
query checkIns($filters: CheckInWhereInput, $first: Int, $skip: Int, $orderByFilter: CheckInOrderByInput){
  checkIns(where: $filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    user{
      id
      firstName
      lastName
      hasUpcomingPlan
      planStatus
    }
    restaurant{
      id
      name
      address
      commission
      discount
      minCheckinPoints
    }
    transaction{
      id
      createdAt
      amount
      commissionAmount
      reverseTransactionData
      # discountAmount
    }
    checkIn
    duration
    checkOut
  }
}
`;

export const REVERSE_PAYMENT = gql`query reversePayment( 
  $transactionID: String!, 
  $points: Float, 
  $lastOverDueAmount: Float,
  $topUp: Float,
  $notes: String,
  $amount:Float){
reversePayment( transactionID: $transactionID,points: $points,lastOverDueAmount: $lastOverDueAmount,topUp: $topUp,notes: $notes,amount:$amount)
}
`;
