import React, { Component, useState, useContext, useEffect } from 'react'
import { Form, Input, Upload, Icon, Button, message, Row, Col, Checkbox, Popconfirm, TimePicker, Divider, Typography, InputNumber, Switch } from 'antd'
import moment from 'moment'
import { UPDATE_RESTAURANT } from '../graphql/Mutations'
import client from '../../../apollo'
import { AppContext } from '../../../AppContext'

const { Title } = Typography

const CollectionCreateForm = Form.create({ name: 'create_restaurant' })(
  class extends Component {
    render() {
      const { visible, isSubmit, onCreate, form, history, restEditableData } = this.props;
      return (
        <AddRestaurantForm
          {...restEditableData}
          isSubmit={isSubmit}
          showModal={visible}
          onCreate={onCreate}
          history={history}
          form={form}
        />
      );
    }
  }
)

function AddRestaurantForm(props) {

  const {
    form: { getFieldDecorator, getFieldValue, validateFields, setFieldsValue },
    id,
    name,
    commission,
    discount,
    address,
    mapUrl,
    images,
    city,
    state: locationState,
    capacity,
    onCreate,
    isSubmit,
    logo,
    restaurantTimings: restTimings,
    Amenity: amenities,
    // minOrder,
    history,
    minCheckinPoints
  } = props;

  const amenityList = [{
    value: 'HIGH_SPEED_WIFI',
    label: 'High Speed WiFi',
  },
  {
    value: 'POWER_POINTS',
    label: 'Power Points ',
  },
  {
    value: 'OUTDOOR_SEATING',
    label: 'Outdoor Seating',
  },
  {
    value: 'FREE_PARKING',
    label: 'Free Parking',
  },
  {
    value: 'AC',
    label: 'AC',
  },
  {
    label: 'Printing & Stationary',
    value: 'PRINTING_STATIONARY'
  },
  {
    label: 'Special iKoverk Menu',
    value: 'SPECIAL_IKOVERK_MENU'
  }
  ]

  const defaultImages = images && images.length > 0 && images.map(((img, key) => {
    return {
      uid: key,
      url: img,
      isUploaded: true
    }
  }))

  const [disabledIndex, setDisabledIndex] = useState([false, false, false, false, false, false, false])
  const [removeFile, setRemoveFile] = useState(false)
  const [fileList, setFileList] = useState(defaultImages || [])
  const { state } = useContext(AppContext)
  const [imageUrl, setImageUrl] = useState('')
  const canViewDetails = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'ADMIN')

  useEffect(() => {
    if (restTimings) {
      setDisabledIndex(restTimings.map(slot => !!slot.isFullDay))
    }
  }, [restTimings])

  const handleChange = ({ file }) => {
    if (removeFile) {
      const temp = fileList.filter((listFile) => JSON.stringify(listFile) !== JSON.stringify(file))
      setFileList(temp)
      if (id) {
        client.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: { data: { images: temp.map((img) => img.url) }, id }
        })
          .catch(e => console.log(e))
      }
      setRemoveFile(false)
    } else {
      setFileList([...fileList, file]);
    }
  }

  function getBase64(img, callback) {
    if (img) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    }
  }

  function beforeUploadTest(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChangeTest(info) {

    getBase64(info.file.originFileObj, imageUrl => {
      setImageUrl(imageUrl)
    }
    );
  };

  const uploadProps = {
    customRequest() {
      return false
    }
  }

  function normFile(e) {
    if (removeFile) {
      setRemoveFile(false)
      return []
    } else {
      return beforeImageUpload(e.file) ? e.fileList = [e.file] : []
    }
  }

  function beforeImageUpload(file) {
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
      return true
    }
    message.error('You can only upload image file!');
    return false
  }

  function handleRemove(e) {
    setRemoveFile(true)
  }

  const days = [0, 1, 2, 3, 4, 5, 6]
  const dayName = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const uploadButton = (
    <div>
      <Icon type={'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const checkStartDate = (rule, value, callback, index, slotIndex) => {
    try {
      const endValue = `day${index}-timeslot${slotIndex}-end`
      validateFields([endValue])
      callback()
    } catch (e) {
      callback(e)
    }
  }

  const checkEndDate = (_rule, endValue, callback, index, slotIndex) => {
    const startValue = getFieldValue(`day${index}-timeslot${slotIndex}-start`)
    try {
      if (!startValue && !endValue) {
        callback()
      } else if (!startValue || !endValue) {
        callback('Both fields are required!')
      }
      // else if (startValue.valueOf() >= endValue.valueOf()) {
      //   callback('Start time must be smaller then end time!')
      // }
      callback()
    } catch (err) {
      callback(err)
    }
  }

  function handleSwitch(bool, index) {
    let arr = disabledIndex
    arr[index] = bool
    setFieldsValue(
      {
        [`day${index}-timeslot1-start`]: null,
        [`day${index}-timeslot2-start`]: null,
        [`day${index}-timeslot1-end`]: null,
        [`day${index}-timeslot2-end`]: null
      }
    )
    setDisabledIndex(arr)
  }

  return (
    <Form colon={false} layout="vertical" className="add-restaurant-form">
      <Row gutter={24} type="flex" >
        <Col span={5} >
          <Form.Item
            label="Thumbnail Image (50p x 50p)"
            className="thumb-wrapper"
          >
            {getFieldDecorator('logo', {
              valuePropName: 'logo',
              getValueFromEvent: normFile,
            })(
              <Upload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                {...uploadProps}
                beforeUpload={beforeUploadTest}
                onChange={handleChangeTest}
              >
                {imageUrl || logo ? <img src={imageUrl || logo} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
            )}
          </Form.Item>
        </Col>
        <Col span={19} >
          <Row gutter={24} type="flex">
            <Col span={9}>
              <Form.Item label="Restaurant Name">
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please provide Restaurant Name"
                    }
                  ],
                  initialValue: name
                })(<Input size="large" />)}
              </Form.Item>
            </Col>
            {
              canViewDetails &&
              <Col span={5}>
                <Form.Item label="Commission (%)">
                  {getFieldDecorator("commission", {
                    rules: [
                      { required: true, message: "Please provide Commission" },
                      { pattern: /^(0|[1-9][0-9]?|100)$/g, message: 'Invalid value' }
                    ],
                    initialValue: commission
                  })(<InputNumber size="large" precision={0} type="number" />)}
                </Form.Item>
              </Col>
            }
            {
              canViewDetails &&
              <Col span={5}>
                <Form.Item label="Discount (%)">
                  {getFieldDecorator("discount", {
                    rules: [
                      { required: true, message: "Please provide Discount" },
                      { pattern: /^(0|[1-9][0-9]?|100)$/g, message: 'Invalid value' }
                    ],
                    initialValue: discount
                  })(<InputNumber size="large" precision={0} type="number" />)}
                </Form.Item>
              </Col>
            }
            <Col span={5}>
              <Form.Item label="Seats">
                {getFieldDecorator("capacity", {
                  rules: [
                    { required: true, message: "Please provide Seats" },
                    { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid value' }
                  ],
                  initialValue: capacity
                })(<Input size="large" type="number" />)}
              </Form.Item>
            </Col>
            {/* {
              canViewDetails &&
              <Col span={5}>
                <Form.Item label="Minimum Order (₹)">
                  {getFieldDecorator("minOrder", {
                    rules: [
                      { required: true, message: "Please provide Minimum Order" },
                      { pattern: /^(0|\+?[1-9]\d*)$/g, message: 'Invalid value' }
                    ],
                    initialValue: minOrder
                  })(<Input size="large" type="number" />)}
                </Form.Item>
              </Col>
            } */}
          </Row>
          <Row gutter={24} type="flex">

            <Col span={6}>
              <Form.Item label="Minimum Checkin Funds">
                {getFieldDecorator("minCheckinPoints", {
                  rules: [
                    { required: true, message: "Please provide Minimum Checkin Funds" },
                    { pattern: /^(0|\+?[1-9]\d*)$/g, message: 'Invalid value' }
                  ],
                  initialValue: minCheckinPoints || minCheckinPoints === 0 ? minCheckinPoints : 50
                })(<Input size="large" type="number" />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Area">
                {getFieldDecorator("address", {
                  rules: [
                    { required: true, message: "Please provide Area" }
                  ],
                  initialValue: address
                })(<Input size="large" />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="City">
                {getFieldDecorator("city", {
                  rules: [{ required: true, message: "Please provide City" }],
                  initialValue: city
                })(<Input size="large" />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="State">
                {getFieldDecorator("state", {
                  rules: [
                    { required: true, message: "Please provide State" }
                  ],
                  initialValue: locationState
                })(<Input size="large" />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row gutter={24} type="flex">
        <Col span={24}>
          <Divider orientation="left">
            <Title level={4}>Location</Title>
          </Divider>
          <Form.Item label="Longitude &amp; Latitude">
            {getFieldDecorator("map", {
              // rules: [{ required: true, message: "Please write the Email Address" }],
              initialValue: map
            })(<Geosuggest country={'in'} onSuggestSelect={(suggest) => console.log(suggest)} inputClassName='ant-input ant-input-lg' />)}
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={24} type="flex">
        <Col span={24}>
          <Divider orientation="left">
            <Title level={4}>Location (URL)</Title>
          </Divider>
          <Form.Item>
            {getFieldDecorator("mapUrl", {
              initialValue: mapUrl
            })(
              <Input size="large" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row className="gallery-wrapper">
        <Divider orientation="left">
          <Title level={4}>Gallery</Title>
        </Divider>
        <Form.Item label="Dimensions: 1280p x 720p">
          {getFieldDecorator("gallery", {
            valuePropName: 'gallery',
          })(
            <Upload
              name="gallery"
              listType="picture"
              {...uploadProps}
              defaultFileList={[...fileList]}
              showUploadList={true}
              onRemove={(e) => handleRemove(e)}
              onChange={(e) => handleChange(e)}
              accept='.jpeg,.png,.jpg'
              multiple={true}
            >
              <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
              </div>
            </Upload>
          )}
        </Form.Item>
      </Row>
      <Row className="news-chat-wrapper">
        <Divider orientation="left">
          <Title level={4}>Weekly Hours Availability</Title>
        </Divider>
        <Row className="news-chat-wrapper" gutter={24} type="flex">
          <Col span={3}>Day</Col>
          <Col span={10}>Slot 1</Col>
          <Col span={7}>Slot 2</Col>
          <Col span={4}>Open 24 Hours</Col>
        </Row>
        {days.map((day, index) => (
          <Row className="news-chat-wrapper" gutter={24} type="flex">
            <Col span={3}>{dayName[index]}</Col>
            <Col span={3} md={4}>
              <Form.Item>
                {getFieldDecorator(`day${index}-timeslot1-start`, {
                  rules: [{
                    validator: (rule, value, callback) => checkStartDate(rule, value, callback, index, 1)
                  }],
                  initialValue: disabledIndex[index] ? null : restTimings && restTimings[index] && restTimings[index].timingSlots && restTimings[index].timingSlots[0] && restTimings[index].timingSlots[0].startTime ? moment(restTimings[index].timingSlots[0].startTime) : null
                })(<TimePicker disabled={disabledIndex[index]} use12Hours defaultOpenValue={moment('09:00 AM', 'hh:mm A')} format={"hh:mm A"} />)}
              </Form.Item>
            </Col>
            <Col span={3} md={4}>
              <Form.Item>
                {getFieldDecorator(`day${index}-timeslot1-end`, {
                  rules: [{
                    validator: (rule, value, callback) => checkEndDate(rule, value, callback, index, 1)
                  }],
                  initialValue: disabledIndex[index] ? null : restTimings && restTimings[index] && restTimings[index].timingSlots && restTimings[index].timingSlots[0] && restTimings[index].timingSlots[0].endTime ? moment(restTimings[index].timingSlots[0].endTime) : null
                })(<TimePicker disabled={disabledIndex[index]} use12Hours defaultOpenValue={moment('01:00 PM', 'hh:mm A')} format={"hh:mm A"} />)}
              </Form.Item>
            </Col>
            <Col span={3} offset={2} md={4}>
              <Form.Item>
                {getFieldDecorator(`day${index}-timeslot2-start`, {
                  rules: [{
                    validator: (rule, value, callback) => checkStartDate(rule, value, callback, index, 2)
                  }],
                  initialValue: disabledIndex[index] ? null : restTimings && restTimings[index] && restTimings[index].timingSlots && restTimings[index].timingSlots[0] && restTimings[index].timingSlots[1].startTime ? moment(restTimings[index].timingSlots[1].startTime) : null
                })(<TimePicker disabled={disabledIndex[index]} use12Hours defaultOpenValue={moment('02:00 PM', 'hh:mm A')} format={"hh:mm A"} />)}
              </Form.Item>
            </Col>

            <Col span={3} md={4}>
              <Form.Item>
                {getFieldDecorator(`day${index}-timeslot2-end`, {
                  rules: [{
                    validator: (rule, value, callback) => checkEndDate(rule, value, callback, index, 2)
                  }],
                  initialValue: disabledIndex[index] ? null : restTimings && restTimings[index] && restTimings[index].timingSlots && restTimings[index].timingSlots[0] && restTimings[index].timingSlots[1].endTime ? moment(restTimings[index].timingSlots[1].endTime) : null
                })(<TimePicker disabled={disabledIndex[index]} use12Hours defaultOpenValue={moment('07:00 PM', 'hh:mm A')} format={"hh:mm A"} />)}
              </Form.Item>
            </Col>
            <Col span={2} >
              <Form.Item>
                {getFieldDecorator(`day${index}-fullDay`, {
                  initialValue: disabledIndex[index]
                })(<Switch size="small" onChange={(e) => handleSwitch(e, index)} defaultChecked={disabledIndex[index]} checked={disabledIndex[index]} />)}
              </Form.Item>
            </Col>
          </Row>
        ))}
      </Row>
      <Row className="news-chat-wrapper">
        <Divider orientation="left">
          <Title level={4}>Amenities</Title>
        </Divider>
        <Row className="news-chat-wrapper" gutter={24} type="flex">
          <Form.Item className='width-100'>
            {getFieldDecorator(`amenities`, {
              initialValue: amenities
            })(<Checkbox.Group className='width-100'  >
              <Row>{
                amenityList.map(amenity => (
                  <Col span={12} >
                    <Checkbox value={amenity.value} className='amenity-checkboxes'>{amenity.label}</Checkbox>
                  </Col>
                ))
              }
              </Row>
            </Checkbox.Group>)}
          </Form.Item>
        </Row>
      </Row>
      <Row className="news-chat-wrapper">
        <Col span={3} offset={16}>
          <Popconfirm
            title={`Are you sure you want to leave this page? You will loose unsaved data.`}
            onConfirm={() => {
              if (id) {
                history.push(`/restaurants/${id}`);
              } else {
                history.push(`/restaurants`);
              }
            }}
          >
            <Button type="info" size="large" block>
              Cancel
            </Button>
          </Popconfirm>
        </Col>
        <Col span={3} offset={1}>
          <Button
            type="primary"
            size="large"
            key="submit"
            block
            onClick={onCreate}
            loading={isSubmit}
          >
            {name ? 'Save' : 'Submit'}
          </Button>
        </Col>
      </Row>
    </Form >
  );
}

class RestaurantForm extends Component {
  render() {
    const { onCreate, saveFormRef, restEditableData, restTimings, history, isSubmit } = this.props;
    const formProps = {
      wrappedComponentRef: saveFormRef,
      restTimings,
      onCreate,
      restEditableData,
      history,
      isSubmit
    }
    return (
      <CollectionCreateForm {...formProps} />
    );
  }
}

export default RestaurantForm
