import React, { Fragment } from 'react'
import { Row, Col, Typography, Statistic, Divider, Table } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { GET_RESTAURANT_CALCULATION } from '../graphql/Queries'
import Spinner from '../../../components/loaders/Spinner'
import '../restaurants.css'

const { Title } = Typography

function Details(props) {
  const { match: { params: { restaurantId } }, canViewDetails } = props
  const { restaurantLoad, postTableData, restaurantData } = props
  // const [restCalculation, setRestCalculation] = useState('');
  const { loading, data } = useQuery(GET_RESTAURANT_CALCULATION, { variables: { id: restaurantId }, fetchPolicy: 'network-only' })

  const column = [
    {
      title: 'Day',
      dataIndex: 'day',
      width: '30%',

    },
    {
      title: 'Slot 1',
      dataIndex: 'slot1',
      width: '35%',
      render: (slot1) => slot1 && slot1.startTime && slot1.endTime ? `${moment(slot1.startTime).format('hh:mm A')} - ${moment(slot1.endTime).format('hh:mm A')} ` : '-'
    },
    {
      title: 'Slot 2',
      dataIndex: 'slot2',
      width: '35%',
      render: (slot2) => slot2 && slot2.startTime && slot2.endTime ? `${moment(slot2.startTime).format('hh:mm A')} - ${moment(slot2.endTime).format('hh:mm A')} ` : '-'

    }
  ]
  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  return (
    loading ?
      <div className="callback-wrapper">
        <Spinner />
      </div>
      :
      <Fragment>{
        canViewDetails && data ?
          <Row gutter={16}>
            <Col span={4}>
              <Statistic title="Total Transaction (₹)" value={data.restaurantCalculation ? Math.round(data.restaurantCalculation.totalTransactionAmount) : '-'} />
            </Col>
            <Col span={4}>
              <Statistic title="Discount" value={data.restaurantCalculation && (data.restaurantCalculation.discount || data.restaurantCalculation.discount === 0) ? data.restaurantCalculation.discount : '-'} suffix={'%'} />
            </Col><Col span={4}>
              <Statistic title="Commission" value={data.restaurantCalculation && (data.restaurantCalculation.commission || data.restaurantCalculation.commission === 0) ? data.restaurantCalculation.commission : '-'} suffix={'%'} />
            </Col><Col span={4}>
              <Statistic title="Total Commission (₹)" value={data.restaurantCalculation && data.restaurantCalculation.commissionAmount ? Math.round(data.restaurantCalculation.commissionAmount) : '-'} />
            </Col><Col span={4}>
              <Statistic title="Paid Amount (₹)" value={data.restaurantCalculation ? Math.round(data.restaurantCalculation.paidAmount) : '-'} />
            </Col><Col span={4}>
              <Statistic title="Due Amount (₹)" value={data.restaurantCalculation ? Math.round(data.restaurantCalculation.dueAmount) : '-'} precision={0} />
            </Col>
          </Row>
          : null
      }

        <Row gutter={24} type="flex" className="news-chat-wrapper">
          <Divider orientation='left'><Title level={4}>Weekly Hours Availabilities</Title></Divider>
          <Col span={24}>
            <Table
              scroll={{ x: true }}
              bordered
              loading={restaurantLoad}
              dataSource={postTableData}
              columns={columns}
              pagination={false}
            />
          </Col>
        </Row>
        <Divider orientation='left'><Title level={4}>Gallery</Title></Divider>
        <Row gutter={24} type="flex" className="gallery-showcase-wrapper">
          {
            !restaurantLoad && restaurantData.images && restaurantData.images.length > 0 ?
              restaurantData.images.map((image, key) => <Col style={{ marginBottom: 15 }} span={4}><img src={image} alt={key} /></Col>)
              :
              <Col span={24}>No Images</Col>
          }
        </Row>
        <br />
      </Fragment>
  )
}
export default withRouter(Details)
