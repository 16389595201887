import React, { useState, useRef, useContext, Fragment } from 'react'
import { PageHeader, notification, Typography, } from 'antd'
import Compressor from 'compressorjs';
import moment from 'moment'
import { AppContext } from '../../../AppContext'
import client from '../../../apollo'
import { CREATE_RESTAURANT, UPDATE_RESTAURANT } from '../graphql/Mutations'
import { GET_RESTAURANT, GET_SIGNED_URLS } from '../graphql/Queries'
import Spinner from '../../../components/loaders/Spinner'
import { fileUpload } from '../../../common/fileUpload'
import RestaurantForm from './RestaurantForm'
import '../restaurants.css'

const { Title } = Typography

function openNotification(type, message) {
  notification[type]({
    message,
    duration: 3
  })
}

export default function AddRestaurant(props) {
  const saveFormRef = useRef()
  const { history, match: { params: { restaurantId } } } = props
  const { state } = useContext(AppContext)
  const canUpdateDetails = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'ADMIN')
  const [isSubmit, setIsSubmit] = useState(false)

  Compressor.setDefaults({
    quality: 0.7,
    convertSize: 5000000,
    checkOrientation: true,
  })

  function uploadCompressImage(url, image) {
    return new Promise((resolve, reject) => {
      Compressor.noConflict();
      new Compressor(image, {
        success(compressedImage) {
          console.log(compressedImage)
          fileUpload(url, compressedImage).then((res) => {
            resolve(res)
          })
        },
        error(err) {
          fileUpload(url, image).then((res) => {
            console.log(res)
            reject(err)
          })
        }
      })
    })
  }

  function handleCreate() {
    setIsSubmit(true)
    const form = saveFormRef.current.props.form
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        setIsSubmit(false)
        return
      }
      const schedule = [0, 1, 2, 3, 4, 5, 6].map(i => ({
        day: i,
        isFullDay: !!values[`day${i}-fullDay`],
        timingSlots: [{
          startTime: !!values[`day${i}-fullDay`] ? moment("2020-01-01T18:30:00.000Z") : values[`day${i}-timeslot1-start`],
          endTime: !!values[`day${i}-fullDay`] ? moment("2020-01-01T18:29:59.999Z") : values[`day${i}-timeslot1-end`],
          isNextDay: !!!values[`day${i}-fullDay`] && values[`day${i}-timeslot1-end`] && values[`day${i}-timeslot1-start`] ? values[`day${i}-timeslot1-end`] <= values[`day${i}-timeslot1-start`] : false,
        }, {
          startTime: !!values[`day${i}-fullDay`] ? null : values[`day${i}-timeslot2-start`],
          endTime: !!values[`day${i}-fullDay`] ? null : values[`day${i}-timeslot2-end`],
          isNextDay: !!!values[`day${i}-fullDay`] && values[`day${i}-timeslot2-end`] && values[`day${i}-timeslot2-start`] ? values[`day${i}-timeslot2-end`] <= values[`day${i}-timeslot2-start`] : false,
        }]
      }))
      // console.log(schedule)
      // setIsSubmit(false)
      // return
      const { name, commission, discount, address, city, state, mapUrl, capacity, logo, gallery, amenities, /*minOrder*/ minCheckinPoints } = values
      const galleryImages = []
      const data = {
        name: name,
        commission: parseFloat(commission),
        discount: parseFloat(discount),
        address,
        city,
        state,
        mapUrl,
        capacity: parseInt(capacity),
        restaurantTimings: schedule,
        Amenity: amenities,
        minCheckinPoints: parseInt(minCheckinPoints)
        // minOrder: parseInt(minOrder)
      }
      if (restEditableData) {
        if (!canUpdateDetails) {
          delete data.commission
          delete data.discount
          // delete data.minOrder
        }
        try {
          client.mutate({
            mutation: UPDATE_RESTAURANT, variables: { data, id: restEditableData.id }
          })
            .then(async res => {
              const restId = restEditableData.id
              if (logo && logo.length > 0) {
                const tempFilename = logo[0].name.split('.')
                const fileExt = tempFilename[tempFilename.length - 1]
                const fileName = `${restId}/${restId}-${(new Date()).getTime()}.${fileExt}`
                const contentType = logo[0].type
                const getSignedPutUrlsResult = await client.query({
                  query: GET_SIGNED_URLS,
                  variables: { data: [{ fileName, contentType }] }
                })
                await uploadCompressImage(getSignedPutUrlsResult.data.getSignedPutUrls.signedUrls[0], logo[0].originFileObj)
                await client.mutate({
                  mutation: UPDATE_RESTAURANT,
                  variables: {
                    data:
                      { logo: getSignedPutUrlsResult.data.getSignedPutUrls.getUrls[0] },
                    id: restId
                  }
                }).catch(e => {
                  console.log(e)
                  openNotification('error', 'Something Went Wrong!')
                  setIsSubmit(false)
                })
              }
              let tempGalleryImages = []
              let alreadyUploaded = []
              if (gallery && gallery.fileList.length > 0) {
                alreadyUploaded = gallery.fileList.filter((img) => img.isUploaded).map((img) => img.url)
                gallery.fileList.forEach((image, key) => {
                  if (!image.isUploaded) {
                    const tempFilename = image.name.split('.')
                    const fileExt = tempFilename[tempFilename.length - 1]
                    const fileName = `${restId}/${restId}-${(new Date()).getTime()}-${Math.floor(Math.random() * 10000)}.${fileExt}`
                    const contentType = image.type
                    galleryImages.push({ fileName, contentType })
                    tempGalleryImages.push(gallery.fileList[key])
                  }
                })
                if (galleryImages && galleryImages.length > 0) {
                  await client.query({
                    query: GET_SIGNED_URLS,
                    variables: { data: galleryImages }
                  }).then(async res => {
                    for (const [index, signedUrl] of res.data.getSignedPutUrls.signedUrls.entries()) {
                      await uploadCompressImage(signedUrl, tempGalleryImages[index].originFileObj)
                    }
                    await client.mutate({
                      mutation: UPDATE_RESTAURANT,
                      variables: {
                        data:
                          { images: [...alreadyUploaded, ...res.data.getSignedPutUrls.getUrls] },
                        id: restId
                      }
                    })
                  }).catch(e => {
                    setIsSubmit(false)
                    console.log(e)
                    openNotification('error', 'Something Went Wrong!')
                  })
                }
              }
            })
            .then(() => {
              openNotification('success', 'Restaurant updated successfully!');
              props.history.push(`/restaurants/${restEditableData.id}`)
              setRestEditableData("")
              setIsSubmit(false)
            })
            .catch(e => {
              setIsSubmit(false)
              console.log(e)
              openNotification('error', 'Something Went Wrong!')
            })
        } catch (e) {
          setIsSubmit(false)
          openNotification('error', 'Something Went Wrong!')
          console.log(e)
        }
      } else {
        try {
          client.mutate({
            mutation: CREATE_RESTAURANT,
            variables: { data },
          })
            .then(async restResult => {
              const restId = restResult.data.createRestaurant.id
              if (logo && logo.length > 0) {
                const tempFilename = logo[0].name.split('.')
                const fileExt = tempFilename[tempFilename.length - 1]
                const fileName = `${restId}/${restId}-${(new Date()).getTime()}.${fileExt}`
                const contentType = logo[0].type
                const getSignedPutUrlsResult = await client.query({
                  query: GET_SIGNED_URLS,
                  variables: { data: [{ fileName, contentType }] }
                })
                await uploadCompressImage(getSignedPutUrlsResult.data.getSignedPutUrls.signedUrls[0], logo[0].originFileObj)
                await client.mutate({
                  mutation: UPDATE_RESTAURANT,
                  variables: {
                    data:
                      { logo: getSignedPutUrlsResult.data.getSignedPutUrls.getUrls[0] },
                    id: restId
                  }
                }).catch(e => {
                  setIsSubmit(false)
                  console.log(e)
                  openNotification('error', 'Something Went Wrong!')
                })
              }
              if (gallery && gallery.fileList.length > 0) {
                gallery.fileList.forEach(image => {
                  const tempFilename = image.name.split('.')
                  const fileExt = tempFilename[tempFilename.length - 1]
                  const fileName = `${restId}/${restId}-${(new Date()).getTime()}-${Math.floor(Math.random() * 10000)}.${fileExt}`
                  const contentType = image.type
                  galleryImages.push({ fileName, contentType })
                }
                )
                await client.query({
                  query: GET_SIGNED_URLS,
                  variables: { data: galleryImages }
                })
                  .then(async res => {
                    for (const [index, signedUrl] of res.data.getSignedPutUrls.signedUrls.entries()) {
                      await uploadCompressImage(signedUrl, gallery.fileList[index].originFileObj)
                    }
                    await client.mutate({
                      mutation: UPDATE_RESTAURANT,
                      variables: {
                        data:
                          { images: res.data.getSignedPutUrls.getUrls },
                        id: restId
                      }
                    })
                      .catch(e => {
                        setIsSubmit(false)
                        console.log(e)
                        openNotification('error', 'Something Went Wrong!')
                      })
                  })
              }
            })
            .then(res => {
              openNotification('success', 'Restaurant created successfully!');
              props.history.push('/restaurants')
              setRestEditableData("")
              setIsSubmit(false)
            }).catch((e) => {
              setIsSubmit(false)
              openNotification('error', 'Something Went Wrong!')
              console.log(e)
            })
        } catch (e) {
          setIsSubmit(false)
          openNotification('error', 'Something Went Wrong!')
          console.log(e)
        }
      }
    })
  }

  const [restEditableData, setRestEditableData] = useState("")
  const [loading, setLoading] = useState(restaurantId ? true : false)
  if (restaurantId) {
    client.query({ query: GET_RESTAURANT, variables: { id: restaurantId }, fetchPolicy: 'network-only' })
      .then(data => {
        setRestEditableData(data.data.restaurant)
        setLoading(false)
      })
      .catch((e) => { console.log(e); openNotification('error', 'Something went wrong!') })
  }

  return (
    <Fragment>
      <div className={`restaurant-wrapper`}>
        <PageHeader
          title={
            <Title level={3}>{restaurantId ? 'Edit' : 'Add'} Restaurant</Title>}
          className="box"
          onBack={() => history.goBack()}
        />
      </div>
      <div className="newsChat-detailpage-wrapper">
        {
          loading ? <Spinner /> :
            <RestaurantForm
              saveFormRef={saveFormRef}
              onCreate={handleCreate}
              restEditableData={restEditableData}
              history={history}
              isSubmit={isSubmit}
            />
        }
      </div>
    </Fragment>
  )
}