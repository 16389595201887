import gql from 'graphql-tag'

export const USER_CONNECTIONS = gql`
query userConnections($filters: UserWhereInput, $orderByFilter: UserOrderByInput){
  usersConnection(where: $filters, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;

export const GET_USERS = gql`
query users($filters: UserWhereInput, $first: Int, $skip: Int, $orderByFilter: UserOrderByInput){
  users(where: $filters, first: $first, skip: $skip,  orderBy: $orderByFilter) {
    id
    firstName
    lastName
    email
    phone
    profileImage
    isActive
    role
    planStatus
    notification
    hasUpcomingPlan
    points
    topUp
    createdAt
    userSubscription{
      id
      startTime
      endTime
      plan{
        id
        name
      }
    }
    restaurant{
      id
      name
    }
  }
}
`;

export const GET_USER_SUBSCRIPTION = gql` 
query userSubscriptions($filters: UserSubscriptionWhereInput, $first:Int, $skip:Int, $orderByFilter: UserSubscriptionOrderByInput){
	userSubscriptions(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    id
    plans
    startTime
    endTime
  }
}`;

export const GET_USER_SUBSCRIPTION_CONNECTION = gql` 
query userSubscriptionsConnection($filters: UserSubscriptionWhereInput, $first:Int, $skip:Int, $orderByFilter: UserSubscriptionOrderByInput){
	userSubscriptionsConnection(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;

export const GET_TOPUP_HISTORY = gql`
query topUps($filters: TopUpWhereInput, $first:Int, $skip:Int, $orderByFilter: TopUpOrderByInput){
  topUps(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    id
    topUpAmount
    payment{
      transactionId
      createdBy{
        firstName,
        lastName
      }
    }
    date
  }
}
`;

export const GET_TOPUP_HISTORY_CONNECTION = gql`
query topUpsConnection($filters: TopUpWhereInput, $first:Int, $skip:Int, $orderByFilter: TopUpOrderByInput){
  topUpsConnection(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}
`;

export const GET_USER = gql`
query user($id: ID!){
  user(where: {id: $id}){
    id
    firstName
    lastName
    email
    phone
    points
    planStatus
    role
    profileImage
    notification
    hasUpcomingPlan
    topUp
    }
  }`

export const GET_CURRENT_PLAN = gql`
query userSubscriptions($status: UserSubscribeStatus $id: ID!){
  userSubscriptions(where: {status: $status,user:{id: $id}}){
    id
    endTime
    plans
  }
}
`;
export const GET_PLANS_WITH_FILTER = gql`
query plans($isDisabled: Boolean){
  plans(where:{isDisabled: $isDisabled}){
    id,
    name,
    points,
    validity,
    price,
    isDisabled,
    type,
    renewAmount
  }
}
`;

export const USER_PROFILE_DETAIL = gql`
query userHistories($id: ID!){
  userHistories(where: {user: {id: $id}})
}`;

export const LOG_HISTORY_CONNECTION = gql`
query logHistoriesConnection($filters: LogHistoryWhereInput, $first:Int, $skip:Int, $orderByFilter: LogHistoryOrderByInput){
  logHistoriesConnection(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}
`;

export const LOG_HISTORY = gql`
query logHistories($filters: LogHistoryWhereInput, $first:Int, $skip:Int, $orderByFilter: LogHistoryOrderByInput){
  logHistories(where:$filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    type
    name
    points
    description
    creditDebit
    createdAt
  }
}
`;