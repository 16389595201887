// import React from 'react'
import { notification } from 'antd'
import moment from 'moment'
import client from '../../apollo'
import { GET_USERS } from '../customers/graphql/Queries'
import { GET_CHECKINS } from '../checkins/graphql/Queries'
import { GET_SUBSCRIPTIONS, GET_TOPUPS } from './graphql/Queries'
import ErrorMessages from '../../common/ErrorMessages'

function openNotification(type, message) {
  notification[type]({
    message,
    duration: 3
  })
}

async function registeredUsers(dateRange) {
  try {
    const filters = {
      role: 'USER',
      createdAt_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
      createdAt_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
    }
    const data = await client.query({
      query: GET_USERS,
      variables: {
        filters,
        orderByFilter: 'createdAt_ASC',
      },
      fetchPolicy: 'network-only'
    })
    if (data && data.data && data.data.users && data.data.users.length > 0) {
      const csvData = await data.data.users.map((user) => (
        {
          // id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
          'User Id': user.id ? user.id : '-',
          'Customer Name': `${user.firstName ? user.firstName : '-'} ${user.lastName ? user.lastName : '-'}`,
          'Phone Number': user.phone ? user.phone : '-',
          'Email': user.email ? user.email : '-',
          'Registered On': user.createdAt ? moment(user.createdAt).format('DD/MM/YYYY') : '-',
          'Status': user.isActive !== null && user.isActive ? 'Active' : 'Deactive',
          'Funds': user.points ? user.points : '-',
          'Top up': user.topUps ? user.topUps : '-',
          'Plan Status': user.planStatus ? user.planStatus : '-',
          'Current Plan': user.userSubscription && user.userSubscription.plan && user.userSubscription.plan.name ? user.userSubscription.plan.name : '-',
          'Has Upcoming Plan': user.hasUpcomingPlan ? 'Yes' : 'No',
          'Plan Subscribed On': user.userSubscription && user.userSubscription.startTime ? moment(user.userSubscription.startTime).format('DD/MM/YYYY') : null,
          'Plan Expires': user.userSubscription && user.userSubscription.endTime ? moment(user.userSubscription.endTime).format('DD/MM/YYYY') : '-',
        }
      ))
      return csvData
    } else {
      openNotification('info', 'No customer have registerd in selected time period!')
      return
    }
  } catch (e) {
    openNotification('error', ErrorMessages(e))
    console.log(e)
    return
  }
}

async function subscriptions(dateRange, phone) {
  try {
    const filters = {
      createdAt_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
      createdAt_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
    }
    if (phone && phone.length === 10) {
      filters['user'] = { phone }
    }
    const data = await client.query({
      query: GET_SUBSCRIPTIONS,
      variables: {
        filters,
        orderByFilter: 'createdAt_ASC',
      },
      fetchPolicy: 'network-only'
    })
    if (data && data.data && data.data.userSubscriptions && data.data.userSubscriptions.length > 0) {
      const csvData = await data.data.userSubscriptions.map((row) => (
        {
          // id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
          'Subscription ID': row.id ? row.id : '-',
          'Customer Name': row.user ? `${row.user.firstName ? row.user.firstName : '-'} ${row.user.lastName ? row.user.lastName : '-'}` : '-',
          'Phone Number': row.user && row.user.phone ? row.user.phone : '-',
          'Plan': row.plan && row.plan.name ? row.plan.name : '-',
          'Funds': row.points ? row.points : '-',
          'Status': row.status ? row.status : '-',
          'Plan Subscribed On': row.startTime ? moment(row.startTime).format('DD/MM/YYYY') : '-',
          'Plan Expires': row.endTime ? moment(row.endTime).format('DD/MM/YYYY') : '-',
        }
      ))
      return csvData
    } else {
      openNotification('info', 'No customer have subscribed in selected time period!')
      return
    }
  } catch (e) {
    openNotification('error', ErrorMessages(e))
    console.log(e)
    return
  }
}

async function topUp(dateRange, phone) {
  try {
    const filters = {
      createdAt_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
      createdAt_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
    }
    if (phone && phone.length === 10) {
      filters['user'] = { phone }
    }
    const data = await client.query({
      query: GET_TOPUPS,
      variables: {
        filters,
        orderByFilter: 'createdAt_ASC',
      },
      fetchPolicy: 'network-only'
    })
    if (data && data.data && data.data.topUps && data.data.topUps.length > 0) {
      const csvData = await data.data.topUps.map((row) => (
        {
          // id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
          'Topup ID': row.id ? row.id : '-',
          'Customer Name': row.user ? `${row.user.firstName ? row.user.firstName : '-'} ${row.user.lastName ? row.user.lastName : '-'}` : '-',
          'Phone Number': row.user && row.user.phone ? row.user.phone : '-',
          'Topup Done By': row.payment && row.payment.createdBy && row.payment.createdBy.role ? row.payment.createdBy.role : '-',
          'Amount': row.topUpAmount ? row.topUpAmount : '-',
          'Date': row.date ? moment(row.date).format('DD/MM/YYYY') : '-',
        }
      ))
      return csvData
    } else {
      openNotification('info', 'No customer have added top up in selected time period!')
      return
    }
  } catch (e) {
    openNotification('error', ErrorMessages(e))
    console.log(e)
    return
  }
}

async function checkIns(dateRange, restaurant) {
  let restFilter = {}
  if (restaurant && restaurant.length !== 0) {
    restFilter['OR'] = restaurant.map(resId => ({ id: resId }))
  }
  const data = {
    restaurant: restFilter,
    checkIn_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
    checkIn_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
  }
  try {
    return client.query({
      query: GET_CHECKINS,
      variables: {
        filters: data,
        orderByFilter: 'checkIn_ASC',
      },
      fetchPolicy: 'network-only'
    }).then((data) => {
      if (data && data.data && data.data.checkIns && data.data.checkIns.length > 0) {
        return data.data.checkIns.map((checkIn) => (
          {
            'Transaction Id': checkIn.transaction && checkIn.transaction.id ? checkIn.transaction.id : '-',
            'Customer Name': `${checkIn.user && checkIn.user.firstName ? checkIn.user.firstName : '-'} ${checkIn.user && checkIn.user.lastName ? checkIn.user.lastName : '-'}`,
            'Restaurant': `${checkIn.restaurant && checkIn.restaurant.name ? checkIn.restaurant.name : '-'}, ${checkIn.restaurant && checkIn.restaurant.address ? checkIn.restaurant.address : ''}`,
            'Check In Time': checkIn.checkIn ? moment(checkIn.checkIn).format('DD-MM-YYYY hh:mm A') : '-',
            'Check Out Time': checkIn.checkOut ? moment(checkIn.checkOut).format('DD-MM-YYYY hh:mm A') : '-',
            'Duration (Minutes)': checkIn.duration,
            'Payment Time': checkIn.transaction && checkIn.transaction.createdAt ? moment(checkIn.transaction.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
            'Amount': checkIn.transaction && checkIn.transaction.amount ? checkIn.transaction.amount : '-',
            'Commission': checkIn.transaction && checkIn.transaction.commissionAmount ? Math.round(checkIn.transaction.commissionAmount) : '-',
            'Reverse Transaction Amount': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.amount ? Math.abs(checkIn.transaction.reverseTransactionData.amount) : '-',
            'Reverse Transaction Note': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.notes ? checkIn.transaction.reverseTransactionData.notes : '-',
            'Reverse Transaction Time': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.createdAt ? moment(checkIn.transaction.reverseTransactionData.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
          }
        ))
      } else {
        openNotification('info', 'Selected restaurant have 0 checkins in selected time period!')
        return
      }
    })
  } catch (e) {
    openNotification('error', ErrorMessages(e))
    console.log(e)
    return
  }

}


export { registeredUsers, subscriptions, topUp, checkIns }