import React from 'react'
import { Form, Input } from 'antd'

export default function (props) {
  const { getFieldDecorator, firstName, lastName, phone, email } = props
  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          rules: [{ required: true, message: 'Please write the First Name' }],
          initialValue: firstName
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName',
          {
            rules: [{ required: true, message: "Please write the Last Name" }],
            initialValue: lastName
          })(<Input />)}
      </Form.Item>
      <Form.Item label="Contact Number">
        {getFieldDecorator('phone',
          {
            rules: [{ required: true, message: "Please write the Contact Number" },
            { max: 10, message: 'Phone number should consist of 10 digits' },
            { min: 10, message: 'Phone number should consist of 10 digits' }],
            initialValue: phone
          })(<Input type="number" disabled={phone ? true : false} />)}
      </Form.Item>
      <Form.Item label="Email">
        {getFieldDecorator('email',
          {
            initialValue: email
          })(<Input disabled={email ? true : false} />)}
      </Form.Item>
    </Form>
  )
}
