import React, { useEffect, useState } from "react";
import { Button, Input, notification } from "antd";
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
import * as firebase from "firebase/app";
import client from "../../apollo";
import { CHECK_USER, LOGIN } from "./graphql/Queries";
import "./auth.css";
import logo from "../../assets/logo.png";

const cookies = new Cookies();

function Login(props) {
  const [phone, setPhone] = useState("");
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [reSend, setReSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      { size: "invisible" }
    );
  }, []);

  async function handleLogin(tempLoading) {
    client
      .query({
        query: CHECK_USER,
        variables: { phone },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        if (response && response.data && response.data.checkUser) {
          if (!tempLoading) {
            setLoading(true);
          }
          var phoneNumber = `+91${phone}`;
          var appVerifier = window.recaptchaVerifier;
          firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
              if (tempLoading) {
                setReSend(false);
                notification["success"]({
                  message: "OTP Re-Sent",
                  duration: 3,
                });
              }
              setVerifyOtp(true);
              setLoading(false);
            })
            .catch(function (error) {
              // Error; SMS not sent
              notification["error"]({
                message: "SMS not sent",
                duration: 3,
              });
              setLoading(false);
              console.log({ error });
            });
        } else {
          notification["error"]({
            message: "User does not exists",
            duration: 3,
          });
        }
      })
      .catch((error) => {
        console.log({ error });
        notification["error"]({
          message:
            error &&
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].message
              ? error.graphQLErrors[0].message
              : error && error.networkError && error.networkError.message
              ? error.networkError.message
              : "Something went wrong!",
          duration: 3,
        });
      });
  }

  function handleVerifyOtp() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(function (result) {
        const phone = result.user.phoneNumber.replace("+91", "");
        client
          .mutate({
            mutation: LOGIN,
            variables: { phone, password: "test@123" },
          })
          .then((response) => {
            if (response.data.login && response.data.login.message) {
              cookies.set("token", cookies.get("connect.sid").token, {
                maxAge: 48 * 60 * 1000,
              });
              notification["success"]({
                message: "Logged In Successfully!",
                duration: 3,
              });
              props.history.push("/");
            } else {
              notification["error"]({
                message: "Login Failed!1",
                duration: 3,
              });
            }
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            notification["error"]({
              message: "Login Failed!2",
            });
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log({ error });
        // User couldn't sign in (bad verification code?)
        notification["error"]({
          message:
            error.code === "auth/invalid-verification-code"
              ? "Invalid OTP"
              : error.code === "auth/code-expired"
              ? "OTP Expired"
              : "Something went wrong!",
          duration: 3,
        });
        setLoading(false);
      });
  }

  return (
    <div className="login-wrapper">
      <div className="login-form-wrapper">
        <div className="header">
          <img src={logo} alt="iKoVerk" title="iKoVerk" />
        </div>
        <hr />
        <div className="body">
          <Input
            type="number"
            disabled={verifyOtp}
            onChange={(e) => setPhone(e.target.value)}
            onKeyUp={(e) => {
              if (
                (e.keyCode === 13 || e.which === 13) &&
                e.target.value.length === 10
              ) {
                handleLogin(false);
              }
            }}
            placeholder="Enter your phone number"
          />
          {verifyOtp && window.confirmationResult && (
            <Input
              type="number"
              autoFocus={true}
              onKeyUp={(e) => {
                if (
                  (e.keyCode === 13 || e.which === 13) &&
                  e.target.value.length === 6
                ) {
                  handleVerifyOtp();
                }
              }}
              placeholder="Enter your OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          )}
          <Button
            id="sign-in-button"
            type="primary"
            size="large"
            disabled={verifyOtp ? otp.length !== 6 : phone.length !== 10}
            loading={loading}
            onClick={() => (verifyOtp ? handleVerifyOtp() : handleLogin(false))}
          >
            {verifyOtp ? "Verify OTP" : "LOGIN"}
          </Button>
          {verifyOtp && window.confirmationResult && (
            <Button
              disabled={reSend}
              className="resend-otp"
              type="link"
              onClick={(e) => {
                setReSend(true);
                window.grecaptcha.reset(window.recaptchaWidgetId);
                handleLogin(true);
              }}
            >
              {reSend ? "Sending OTP..." : "Resend OTP"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Login);
