import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Form, Input, Select, Switch } from 'antd'
import { GET_RESTAURANTS_LIST } from '../../restaurants/graphql/Queries'

const { Option } = Select

export default function (props) {
  const { getFieldDecorator, firstName, lastName, phone, email, restaurantId, role, notification } = props
  const [restaurantDropDown, setRestaurantDropdown] = useState(false)
  const [manualRole, setManualRole] = useState(false)
  const restaurantMembers = ['RESTAURANT_OWNER', 'RESTAURANT_STAFF']

  useEffect(() => {
    if (role && !manualRole) {
      restaurantMembers.includes(role) ? setRestaurantDropdown(true) : setRestaurantDropdown(false)
    }
  }, [role, restaurantMembers, manualRole])

  function handleChange(role) {
    setManualRole(true)
    restaurantMembers.includes(role) ? setRestaurantDropdown(true) : setRestaurantDropdown(false)
  }

  const { data: resData } = useQuery(GET_RESTAURANTS_LIST, { fetchPolicy: 'network-only' })
  let restaurants = []
  if (resData && resData.restaurants) {
    restaurants = resData.restaurants.map((res, key) => (
      {
        key: key.toString(),
        id: res.id,
        name: res.name,
        address: res.address
      }
    ))
  }

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="First Name">
        {getFieldDecorator('firstName', {
          rules: [{ required: true, message: 'Please write the First Name' }],
          initialValue: firstName
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Last Name">
        {getFieldDecorator('lastName',
          {
            rules: [{ required: true, message: "Please write the Last Name" }],
            initialValue: lastName
          })(<Input />)}
      </Form.Item>
      <Form.Item label="Email Address">
        {getFieldDecorator('email',
          {
            rules: [{
              type: 'email', message: 'The input is not valid Email!',
            }],
            initialValue: email
          })(<Input disabled={email ? true : false} />)}
      </Form.Item>
      <Form.Item label="Contact Number">
        {getFieldDecorator('phone',
          {
            rules: [{ required: true, message: "Please write the Contact Number" },
            { len: 10, message: 'Phone number should consist of 10 digits.' },
            { pattern: /^[0-9]*$/g, message: 'Phone number should consist digits only.' }],
            initialValue: phone
          })(<Input disabled={phone ? true : false} type="number" />)}
      </Form.Item>
      <Form.Item label="User Role">
        {getFieldDecorator('role',
          {
            rules: [{ required: true, message: "Please enter User Role" }],
            initialValue: role
          })(<Select onChange={handleChange}>
            <Option value="ADMIN">Admin</Option>
            <Option value="RESTAURANT_OWNER">Restaurant Owner</Option>
            <Option value="RESTAURANT_STAFF">Restaurant Staff</Option>
            <Option value="IKOVERK_STAFF">iKoverk Staff</Option>
          </Select>)}
      </Form.Item>
      {
        restaurantDropDown ? (<><Form.Item label="Restaurant">
          {getFieldDecorator('restaurantId',
            {
              rules: [{ required: true, message: "Please write the Restaurant Name" }],
              initialValue: restaurantId
            })(<Select >
              {restaurants ? (restaurants.map((res, i) => (<Option key={i} value={res.id}>{`${res.name}, ${res.address}`}</Option>))) : ''}
            </Select>)}
        </Form.Item>
          <Form.Item label="Send Notification">
            {getFieldDecorator('notification',
              {
                valuePropName: 'checked',
                initialValue: notification === undefined ? true : notification,
              })(<Switch size='large' />)}
          </Form.Item>
        </>) : null
      }
    </Form>
  )
}
