import React, { useState } from 'react'
import { Row, Col, Table, Pagination } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { GET_TOPUP_HISTORY, GET_TOPUP_HISTORY_CONNECTION } from '../graphql/Queries'
import Meta from '../../../components/Meta'
import '../customer.css'


function TopupHistory(props) {
  const orderByFilter = "date_DESC"
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const { match: { params: { customerId } } } = props
  const { data: historyData, error: historyError, loading: historyLoad, fetchMore } = useQuery(GET_TOPUP_HISTORY, { variables: { filters: { user: { id: customerId, } }, first: 10, orderByFilter }, fetchPolicy: 'network-only' })
  const { data: topupHistoryCount, loading: countLoading, error: topupHistoryCountError } = useQuery(GET_TOPUP_HISTORY_CONNECTION, { variables: { filters: { user: { id: customerId } }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !topupHistoryCountError ? (topupHistoryCount && topupHistoryCount.topUpsConnection) ? topupHistoryCount.topUpsConnection.aggregate.count : 1 : 1

  const column = [
    {
      title: 'Amount (₹)',
      dataIndex: 'amount',
      width: '35%',
      render: (amount) => amount ? amount : '-'
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: '35%',
      render: (date) => date ? moment(date).format('DD/MM/YYYY, hh:mm A') : '-'
    },
    {
      title: 'Topup Done By',
      dataIndex: 'creator',
      width: '30%',
      render: (creator) => creator ? creator : '-'
    },
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!historyError && historyData && historyData.topUps) {
    postTableData = historyData.topUps.map((row, key) => (
      {
        key: key.toString(),
        amount: row.topUpAmount,
        date: row.date,
        creator: row.payment && row.payment.createdBy && (row.payment.createdBy.firstName || row.payment.createdBy.lastName) ?
          `${row.payment.createdBy.firstName} ${row.payment.createdBy.lastName}`
          :
          (row.payment && row.payment.transactionId ? 'User' : row.payment ? 'iKoVerk Staff' : '-')
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > historyData.topUps.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { topUps } = fetchMoreResult
          setTableLoading(false)
          return topUps.length ? { topUps: [...topUps] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Customers" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={historyLoad || countLoading || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </Col>
    </Row>
  )
}

export default withRouter(TopupHistory)
