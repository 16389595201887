import React, { useState } from 'react'
import { Select, notification, Form, DatePicker, Button, Row, Col, Card, Typography, Input } from 'antd'
import { ExportToCsv } from 'export-to-csv';
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import Meta from '../../components/Meta'
import { GET_RESTAURANTS_LIST } from '../restaurants/graphql/Queries'
import { registeredUsers, topUp, subscriptions, checkIns, } from './ExportFunctions'

const { RangePicker } = DatePicker;
const { Option } = Select
const { Title } = Typography
const options = {
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: 'ikoverk-exports'
};
const csvExporter = new ExportToCsv(options);
const Exports = () => {
  const [isSubmit, setSubmit] = useState(false)

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleExport(e, form) {
    e.preventDefault()
    setSubmit(true)
    // const form = exportFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        setSubmit(false)
        return
      }

      const { choice, dateRange, restaurant, phone } = values
      let data = []
      switch (choice) {
        case 'REGISTERED_USERS':
          data = await registeredUsers(dateRange)
          break
        case 'SUBSCRIPTIONS':
          data = await subscriptions(dateRange, phone)
          break
        case 'TOP_UP':
          data = await topUp(dateRange, phone)
          break
        case 'CHECKINS':
          data = await checkIns(dateRange, restaurant)
          break
        default:
          openNotification('error', 'Please select valid export type!')
      }
      if (data && data.length > 0) {
        csvExporter.generateCsv(data)
      }
      setSubmit(false)
    })
  }

  return (
    <React.Fragment>
      <Row gutter={24} type="flex" justify='center' className="news-chat-wrapper">
        <Meta title="Customers" description="" />
        <Col span={24}>
          <div className="title-wrapper">
            <Title level={2}>Exports</Title>
          </div>
        </Col>
        <Col lg={14} md={18} sm={24}>
          <Card bordered={true}>
            <Row gutter={24} type="flex" justify='center' className="news-chat-wrapper">
              <Col span={22}>
                <ExportForm onSubmit={handleExport} isSubmit={isSubmit} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const ExportForm = Form.create({ name: 'export_customer' })(

  (props) => {
    const { data } = useQuery(GET_RESTAURANTS_LIST, { fetchPolicy: 'network-only' })
    const [showRestaurants, setShowRestaurants] = useState(false)
    const [showMobileInput, setShowMobileInput] = useState(false)
    const { form, onSubmit, isSubmit } = props
    const { getFieldDecorator } = form
    const options = [
      { 'value': 'REGISTERED_USERS', 'label': 'Registered Users' },
      { 'value': 'SUBSCRIPTIONS', 'label': 'Subscriptions' },
      { 'value': 'TOP_UP', 'label': 'Top up' },
      { 'value': 'CHECKINS', 'label': 'Checkins' }
    ]

    function disabledDate(current) {
      // Can not select days before today and today
      return current > moment().endOf('day');
    }

    function handleChange(e) {
      if (e === 'CHECKINS') {
        setShowMobileInput(false)
        setShowRestaurants(true)
      } else if (e === 'SUBSCRIPTIONS' || e === 'TOP_UP') {
        setShowRestaurants(false)
        setShowMobileInput(true)
      } else {
        setShowMobileInput(false)
        setShowRestaurants(false)
      }
    }

    return (
      <Form
        colon={false}
        layout="vertical"
        onSubmit={_ => onSubmit(_, form)}
        className="export-form" >

        <Form.Item label="Select export type">
          {getFieldDecorator('choice',
            {
              initialValue: 'REGISTERED_USERS'
            })(<Select onChange={(e) => handleChange(e)}>
              {options.map((opt, i) => <Option key={i} value={opt.value}>{opt.label}</Option>)}
            </Select>)}
        </Form.Item>
        {
          showRestaurants &&
          <Form.Item label="Select Restaurant">
            {getFieldDecorator(`restaurant`, {
            })(<Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Leave empty for All restaurant"
              optionLabelProp="children"
            >
              {
                data && data.restaurants && data.restaurants.length !== 0 ?
                  data.restaurants.map(res => <Option key={res.id} value={res.id} label={res.id}>{`${res.name}, ${res.address}`}</Option>)
                  :
                  null
              }
            </Select>)}
          </Form.Item>
        }
        {
          showMobileInput &&
          <Form.Item label="Enter Mobile number">
            {getFieldDecorator('phone',
              {
                rules: [
                  { len: 10, message: 'Phone number should consist of 10 digits.' },
                  { pattern: /^[0-9]*$/g, message: 'Phone number should consist digits only.' }
                ],
              })(<Input type="number" placeholder='Leave empty for all users.' />)}
          </Form.Item>
        }
        <Form.Item label="Select Time Period">
          {getFieldDecorator(`dateRange`, {
            initialValue: [moment().startOf('month'), moment()],
            rules: [{
              required: true, message: 'Please select time period!'
            }]
          })(
            <RangePicker
              style={{ width: '100%' }}
              format={'DD-MM-YYYY'}
              disabledDate={disabledDate}
              ranges={{
                'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
                'This Month': [moment().startOf('month'), moment()],
              }}
            />
          )}
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmit} style={{ float: 'right', marginTop: '10px' }}>
          Submit
          </Button>
      </Form>
    )
  }

)

export default Exports