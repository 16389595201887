import React, { useState, useRef, useContext } from 'react'
import { Row, Col, Table, Typography, Pagination, Button, Icon, Tooltip, Popconfirm, Switch, notification, Input } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { AppContext } from '../../AppContext'
import client from '../../apollo'
import { CREATE_TOPUP } from './graphql/Mutations'
import { UPDATE_USER, CREATE_USER, UPDATE_USER_SUBSCRIPTION } from './graphql/Mutations'
import { SET_PLAN, } from './graphql/Mutations'
import { GET_USERS, USER_CONNECTIONS } from './graphql/Queries'
import AddUserModal from './components/AddUserModal'
import TopupModal from './components/TopupModal'
import ImportUserModal from './components/ImportUserModal'
import Meta from '../../components/Meta'
import './customer.css'
import noImage from '../../assets/no-image-user.png'

const { Title } = Typography

export default function Customers(props) {
  const { state } = useContext(AppContext)
  const [tableLoading, setTableLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [showTopupModal, setShowTopupModal] = useState(false)
  const [userEditableData, setUserEditableData] = useState("")
  const [isSubmit, setSubmit] = useState(false)
  const [okStatus, setOkStatus] = useState(true)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("createdAt_DESC")
  const [filters, setFilters] = useState({ role: 'USER' })
  const { data: customerData, error: customerError, refetch, loading: customerLoad, fetchMore } = useQuery(GET_USERS, { variables: { filters, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: customerDataCount, error: customerDataCountError } = useQuery(USER_CONNECTIONS, { variables: { filters, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !customerDataCountError ? (customerDataCount && customerDataCount.usersConnection) ? customerDataCount.usersConnection.aggregate.count : 1 : 1
  const saveFormRef = useRef()
  const topupFormRef = useRef()
  const importFormRef = useRef()

  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    saveFormRef.current.props.form.resetFields()
    topupFormRef.current.props.form.resetFields()
    importFormRef.current.props.form.resetFields()
    setSubmit(false)
    setShowModal(false)
    setShowTopupModal(false)
    setShowImportModal(false)
    setUserEditableData("")
  }

  function handleRequestFail() {
    setTableLoading(false)
    openNotification("error", "Something Went Wrong")
  }

  async function handleCreate() {
    const form = saveFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const { firstName, lastName, phone, email, planId, customValidity } = values
      const data = { firstName, lastName, phone, email, role: 'USER' }
      if (userEditableData) {
        try {
          if (planId && userEditableData.planId !== planId) {
            await client.mutate({
              mutation: SET_PLAN,
              variables: {
                data: {
                  user: { connect: { id: userEditableData.id } },
                  plan: { connect: { id: planId } },
                  endTime: customValidity ? customValidity.format("YYYY-MM-DD") : null
                }
              }
            })
          } else if (planId && userEditableData.planId === planId) {
            if (customValidity && customValidity.format('YYYY-MM-DD') !== moment(userEditableData.endTime).format('YYYY-MM-DD')) {
              await client.mutate({
                mutation: UPDATE_USER_SUBSCRIPTION,
                variables: {
                  id: userEditableData.subscriptionId,
                  endTime: customValidity ? customValidity.format("YYYY-MM-DD") : null
                }
              })
            }
          }
          client.mutate({
            mutation: UPDATE_USER,
            variables: { data, id: userEditableData.id },
            refetchQueries: [{
              query: GET_USERS,
              variables: { filters, orderByFilter, },
              fetchPolicy: 'network-only'
            }]
          })
            .then(res => {
              if (res) {
                openNotification('success', 'Customer updated successfully!');
                setUserEditableData("")
                setShowModal(false)
                setSubmit(false)
                form.resetFields()
              }
            })
            .catch(e => {
              console.log(e)
              setSubmit(false)
            })
        } catch (e) {
          console.log(e)
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
        }
      } else {
        try {
          data.isActive = true
          client.mutate({
            mutation: CREATE_USER,
            variables: {
              data
            },
            refetchQueries: [{
              query: GET_USERS,
              variables: { filters, orderByFilter, first: 10, fetchPolicy: 'network-only' }
            }]
          })
            .then(res => {
              if (planId) {
                if (res.data.createUser.id) {
                  client.mutate({
                    mutation: SET_PLAN,
                    variables: {
                      data: {
                        user: { connect: { id: res.data.createUser.id } },
                        plan: { connect: { id: planId } },
                        endTime: customValidity ? customValidity.format("YYYY-MM-DD") : null
                      }
                    }
                  })
                    .then(res => {
                      refetch()
                      openNotification('success', 'Customer added Successfully!');
                      setUserEditableData("")
                      setShowModal(false)
                      setSubmit(false)
                      form.resetFields()
                    })
                } else {
                  setSubmit(false)
                  openNotification('error', 'Plan not assigned!')
                }
              } else if (res.data.createUser.id) {
                openNotification('success', 'Customer added Successfully!');
                setUserEditableData("")
                setShowModal(false)
                setSubmit(false)
                form.resetFields()
              }
            })
            .catch(e => {
              setSubmit(false)
              console.log(e)
            })
        } catch (e) {
          console.log(e)
          setSubmit(false)
          openNotification('error', 'Something Went Wrong!')
        }
      }
    })
  }

  function handleTopupCreate() {
    const form = topupFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      setSubmit(true)
      const { amount, userId, userSubscriptionId } = values
      client.mutate({
        mutation: CREATE_TOPUP,
        variables: {
          data: {
            user: { connect: { id: userId } },
            topUpAmount: parseFloat(amount),
            payment: {
              create: {
                user: { connect: { id: userId } },
                subscription: { connect: { id: userSubscriptionId } },
                amount: parseFloat(amount),
                transactionStatus: "success",
                transactionResponse: "",
                createdBy: { connect: { id: state.currentUser.id } }
              }
            }
          }
        }
      })
        .then(res => {
          openNotification('success', 'Topup added Successfully!');
          setUserEditableData("")
          setShowTopupModal(false)
          setSubmit(false)
          form.resetFields()
        })
        .catch((e) => {
          console.log(e)
          setSubmit(false)
        })
    })
  }

  function handleImportCreate() {
    setOkStatus(true)
    handleCancel()
  }

  async function handleActive(userId, status) {
    await client.mutate({
      mutation: UPDATE_USER,
      variables: { data: { isActive: !status }, id: userId },
      refetchQueries: [{
        query: GET_USERS,
        variables: { filters }
      }]
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", `Customer ${status ? 'Deactivated' : 'Activated'}!`)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        console.log(e)
        handleRequestFail()
      })
  }

  function handleEditButton(data) {
    setUserEditableData(data)
    setShowModal(true)
  }

  function handleRenewBtn(data) {
    try {
      client.mutate({
        mutation: SET_PLAN,
        variables: {
          data: {
            user: { connect: { id: data.id } },
            plan: { connect: { id: data.planId } }
          }
        },
        refetchQueries: [{
          query: GET_USERS,
          variables: { filters }
        }]
      }).then((e) => {
        openNotification('success', 'Customer Plan Renewed Successfully!');
      }).catch(e => {
        console.log(e)
      })
    } catch (e) {
      console.log(e)
      openNotification('error', 'Something Went Wrong!');
    }

  }

  function handleTopupBtn(data) {
    setUserEditableData(data)
    setShowTopupModal(true)
  }

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={input => input && input.focus()}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setCurrentPageNumber(1)
    confirm()
    let tempFilters = {}
    if (dataIndex === 'firstName') {
      tempFilters["firstName_contains"] = selectedKeys[0]
    } else if (dataIndex === 'lastName') {
      tempFilters["lastName_contains"] = selectedKeys[0]
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = selectedKeys[0]
    } else if (dataIndex === 'phone') {
      tempFilters["phone_contains"] = selectedKeys[0]
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    let tempFilters = {}
    if (dataIndex === 'firstName') {
      tempFilters["firstName_contains"] = ""
    } else if (dataIndex === 'lastName') {
      tempFilters["lastName_contains"] = ""
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = ""
    } else if (dataIndex === 'phone') {
      tempFilters["phone_contains"] = ""
    }
    setFilters({ ...filters, ...tempFilters })
  }

  const column = [
    {
      align: 'center',
      title: 'Profile Image',
      dataIndex: 'profileImage',
      width: '7%',
      render: profileImage => <img className="customer-profile-image" src={profileImage || noImage} alt='profileImage' />
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      width: '7%',
      render: (text, record, index) => record.firstName ? <NavLink to={`/customers/${record.id}`} className="discussions-subject">{`${record.firstName}`}</NavLink> : '-',
      ...getColumnSearchProps('firstName')
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      width: '7%',
      render: (text, record, index) => record.lastName ? record.lastName : '-',
      ...getColumnSearchProps('lastName')
    },
    {
      title: 'Contact',
      dataIndex: 'phone',
      width: '8%',
      render: phone => phone ? phone : '-',
      ...getColumnSearchProps('phone')
    },
    {
      title: 'Registered On',
      dataIndex: 'registeredOn',
      width: '8%',
      render: date => date ? moment(date).format('DD/MM/YYYY') : '-',
    },
    {
      title: 'Plan Status',
      dataIndex: 'planStatus',
      width: '9%',
      render: (planStatus) => planStatus ? planStatus : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Plan Name',
      dataIndex: 'planName',
      width: '8%',
      render: planName => planName ? planName : '-'
    },
    {
      title: 'Plan Subscribed On',
      dataIndex: 'startTime',
      width: '5%',
      render: startTime => startTime ? moment(startTime).format('DD/MM/YYYY') : '-'
    },
    {
      title: 'Plan Expires On',
      dataIndex: 'endTime',
      width: '8%',
      render: endTime => endTime ? moment(endTime).format('DD/MM/YYYY') : '-'
    },

    {
      title: 'Action',
      dataIndex: 'action',
      width: '10%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon type="edit" theme="twoTone" onClick={() => handleEditButton(record)} />
            </Tooltip>
            &emsp;
            <Popconfirm
              title={`Are you sure you want to ${record.isActive ? 'deactivate' : 'activate'} this customer?`}
              onConfirm={() => handleActive(record.id, record.isActive)}
            >
              <Tooltip title={`${record.isActive ? 'Deactivate' : 'Activate'}`}>
                <Switch size='small' defaultChecked={record.isActive} checked={record.isActive} />
              </Tooltip>
            </Popconfirm>
          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      width: '15%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Popconfirm
              title={`Are you sure you want to renew plan for this customer?`}
              onConfirm={() => handleRenewBtn(record)}
              disabled={record.hasUpcomingPlan || record.planStatus !== 'ACTIVE'}
            >
              <Button type="primary" size='small' disabled={record.hasUpcomingPlan || record.planStatus !== 'ACTIVE'} >Renew </Button>
            </Popconfirm>
            <>
              <Button type="primary" size='small' disabled={!record.userSubscriptionId} onClick={() => handleTopupBtn(record)} >Topup</Button>
            </>
          </div>
        )
      }
    }
  ]

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!customerError && !customerLoad && customerData && customerData.users) {
    postTableData = customerData.users.map((user, key) => (
      {
        key: key.toString(),
        id: user.id,
        lastName: user.lastName,
        firstName: user.firstName,
        email: user.email,
        planStatus: user.planStatus,
        isActive: user.isActive,
        phone: user.phone,
        profileImage: user.profileImage,
        planName: user.userSubscription && user.userSubscription.plan ? user.userSubscription.plan.name : null,
        planId: user.userSubscription && user.userSubscription.plan ? user.userSubscription.plan.id : null,
        userSubscriptionId: user.userSubscription && user.userSubscription.id ? user.userSubscription.id : null,
        hasUpcomingPlan: user.hasUpcomingPlan,
        endTime: user.userSubscription && user.userSubscription.endTime ? user.userSubscription.endTime : null,
        subscriptionId: user.userSubscription && user.userSubscription.id ? user.userSubscription.id : null,
        startTime: user.userSubscription && user.userSubscription.startTime ? user.userSubscription.startTime : null,
        registeredOn: user.createdAt ? user.createdAt : null
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > customerData.users.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { users } = fetchMoreResult
          setTableLoading(false)
          return users.length ? { users: [...users] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Customers" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Customers</Title>
          <div>
            <Button type="primary" size='large' style={{ margin: '10px' }} onClick={() => setShowImportModal(true)}>Import Customers</Button>
            <Button type="primary" size='large' onClick={() => setShowModal(true)}>Add Customer</Button>
          </div>
        </div>
        <Table
          scroll={{ x: true }}
          bordered
          loading={customerLoad || tableLoading}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
        <AddUserModal
          saveFormRef={saveFormRef}
          shoModal={showModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleCreate}
          userEditableData={userEditableData}
        />
        <TopupModal
          saveFormRef={topupFormRef}
          shoModal={showTopupModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleTopupCreate}
          userEditableData={userEditableData}
        />
        <ImportUserModal
          saveFormRef={importFormRef}
          shoModal={showImportModal}
          isSubmit={isSubmit}
          handleCancel={handleCancel}
          handleCreate={handleImportCreate}
          okStatus={okStatus}
          setOkStatus={setOkStatus}
          refetch={refetch}
        />

      </Col>
    </Row>
  )
}
