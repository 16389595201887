import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Spinner from '../../components/loaders/Spinner'

export default withRouter(function ({ history }) {
  const isAuthenticated = false
  useEffect(() => {
    if (isAuthenticated !== undefined && isAuthenticated)
      history.replace('/')
  }, [isAuthenticated, history])
  return (
    <div className="callback-wrapper">
      <Spinner />
    </div>
  )
})
