import React from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { Form, Select, DatePicker } from 'antd'
import { GET_RESTAURANTS_LIST } from '../../restaurants/graphql/Queries'

const { Option } = Select
const { RangePicker } = DatePicker;

export default function (props) {

  const { getFieldDecorator, singleRestaurant } = props
  const { data } = useQuery(GET_RESTAURANTS_LIST, { fetchPolicy: 'network-only' })
  function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
  }

  return (
    <Form colon={false} layout="vertical" className="export-form">
      {
        !singleRestaurant &&
        <Form.Item label="Select Restaurant">
          {getFieldDecorator(`restaurant`, {
          })(<Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Leave empty for All restaurant"
            optionLabelProp="children"
          >
            {
              data && data.restaurants && data.restaurants.length !== 0 ?
                data.restaurants.map(res => <Option key={res.id} value={res.id} label={res.id}>{`${res.name}, ${res.address}`}</Option>) : null}
          </Select>)}
        </Form.Item>
      }
      <Form.Item label="Select Time Period">
        {getFieldDecorator(`dateRange`, {
          initialValue: [moment().startOf('month'), moment()],
          rules: [{
            required: true, message: 'Please select time period!'
          }]
        })(
          <RangePicker
            style={{ width: '100%' }}
            format={'DD-MM-YYYY'}
            disabledDate={disabledDate}
            ranges={{
              'Last Month': [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')],
              'This Month': [moment().startOf('month'), moment()],
            }}
          />
        )}
      </Form.Item>
    </Form>
  )
}
