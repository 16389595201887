import React from 'react'
import { Form, Input, Row, Col, Radio, InputNumber } from 'antd'

export default function (props) {
  const { getFieldDecorator, name, validity, points, price, type, renewAmount } = props

  return (
    <Form colon={false} layout="vertical" className="add-plan-form">
      <Form.Item label="Plan Name">
        {getFieldDecorator('planName', {
          rules: [{ required: true, message: 'Please write Plan Name' }],
          initialValue: name
        })(
          <Input />
        )}
      </Form.Item>
      <Form.Item label="Validity (Days)">
        {getFieldDecorator('validity',
          {
            rules: [{ required: true, message: "Please write Plan Validity" },
            { pattern: /^[1-9]+[0-9]*$/g, message: 'Invalid amount' }],
            initialValue: validity
          })(<InputNumber type="number" precision={0} />)}
      </Form.Item>
      <Form.Item label="Funds">
        {getFieldDecorator('points',
          {
            rules: [{ required: true, message: "Please write Funds" },
            { pattern: /^[0-9]*$/g, message: 'Invalid amount' }],
            initialValue: points
          })(<InputNumber type="number" precision={0} />)}
      </Form.Item>
      <Form.Item label="Price">
        {getFieldDecorator('price',
          {
            rules: [{ required: true, message: "Please write Price" },
            { pattern: /^[0-9]*$/g, message: 'Invalid amount' }],
            initialValue: price
          })(<InputNumber type="number" precision={0} />)}
      </Form.Item>
      <Form.Item label="Renew Price">
        {getFieldDecorator('renewAmount',
          {
            rules: [{ required: true, message: "Please write Renew Price" },
            { pattern: /^[0-9]*$/g, message: 'Invalid amount' }],
            initialValue: renewAmount
          })(<InputNumber type="number" precision={0} />)}
      </Form.Item>
      <Row>
        <Col span={12}><Form.Item label="Plan Type">
          {getFieldDecorator('type',
            {
              rules: [{ required: true, message: "Please enter Plan Type" }],
              initialValue: type
            })(
              <Radio.Group >
                <Radio value={'PUBLIC'}>Public</Radio>
                <Radio value={'PRIVATE'} >Private</Radio>
              </Radio.Group>
            )}
        </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
