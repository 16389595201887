import React, { useContext, useState, useRef } from 'react'
import { Row, Col, PageHeader, Button, Tabs, Typography, notification } from 'antd'
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import client from '../../../apollo'
import { GET_CHECKINS } from '../../checkins/graphql/Queries'
import { GET_RESTAURANT } from './../graphql/Queries'
import Spinner from '../../../components/loaders/Spinner'
import Page404 from '../../../components/Page404'
import { AppContext } from '../../../AppContext'
import ExportModal from '../../checkins/components/ExportModal'
import Meta from '../../../components/Meta'
import Payments from './Commission'
import CheckIns from './CheckIns'
import ErrorMessages from '../../../common/ErrorMessages'
import Details from './Details'
import Staff from './Staff'
import '../restaurants.css'

const { Title } = Typography
const { TabPane } = Tabs
const getDay = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday'
}
const options = {
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: 'ikoverk-checkins'
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
const csvExporter = new ExportToCsv(options);

export default function RestaurantDetailPage({ history, match: { params: { restaurantId } } }) {
  const { state } = useContext(AppContext)
  const { data, error, loading, networkStatus } = useQuery(GET_RESTAURANT, { variables: { id: restaurantId }, fetchPolicy: "network-only" })
  const restaurantData = !loading && !error && data && data.restaurant ? data.restaurant : null
  const exportFormRef = useRef()
  let postTableData = []
  const [showExportModal, setShowExportModal] = useState(false)
  const [isSubmit, setSubmit] = useState(false)
  const isRestaurantMember = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'RESTAURANT_STAFF')
  const canViewDetails = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'RESTAURANT_OWNER' || state.currentUser.role === 'ADMIN')

  if (restaurantData) {
    postTableData = data.restaurant.restaurantTimings.map((day, key) => (
      {
        day: getDay[day.day],
        slot2: day.timingSlots[1],
        slot1: day.timingSlots[0]
      }
    ))
  }


  function openNotification(type, message) {
    notification[type]({
      message,
      duration: 3
    })
  }

  function handleCancel() {
    exportFormRef.current.props.form.resetFields()
    setShowExportModal(false)
    setSubmit(false)
  }

  function handleExport() {
    const form = exportFormRef.current.props.form
    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      const { dateRange } = values
      const data = {
        restaurant: { id: restaurantId },
        checkIn_gte: `${dateRange[0].format('YYYY-MM-DD')}T00:00:00.000Z`,
        checkIn_lte: `${dateRange[1].format('YYYY-MM-DD')}T23:59:59.999Z`
      }
      const orderBy = 'checkIn_ASC'
      try {
        client.query({
          query: GET_CHECKINS,
          variables: {
            filters: data,
            orderByFilter: orderBy,
          },
          fetchPolicy: 'network-only'
        }).then((data) => {
          if (data && data.data && data.data.checkIns && data.data.checkIns.length > 0) {
            const csvData = data.data.checkIns.map((checkIn, key) => (
              {
                // id: checkIn.user && checkIn.user.id ? checkIn.user.id : null,
                'Transaction Id': checkIn.transaction && checkIn.transaction.id ? checkIn.transaction.id : '-',
                'Customer Name': `${checkIn.user && checkIn.user.firstName ? checkIn.user.firstName : '-'} ${checkIn.user && checkIn.user.lastName ? checkIn.user.lastName : '-'}`,
                // 'Restaurant': `${checkIn.restaurant && checkIn.restaurant.name ? checkIn.restaurant.name : '-'}, ${checkIn.restaurant && checkIn.restaurant.address ? checkIn.restaurant.address : ''}`,
                'Check In Time': checkIn.checkIn ? moment(checkIn.checkIn).format('DD-MM-YYYY hh:mm A') : '-',
                'Check Out Time': checkIn.checkOut ? moment(checkIn.checkOut).format('DD-MM-YYYY hh:mm A') : '-',
                'Duration (Minutes)': checkIn.duration,
                'Payment Time': checkIn.transaction && checkIn.transaction.createdAt ? moment(checkIn.transaction.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
                'Amount': checkIn.transaction && checkIn.transaction.amount ? checkIn.transaction.amount : '-',
                'Commission': checkIn.transaction && checkIn.transaction.commissionAmount ? Math.round(checkIn.transaction.commissionAmount) : '-',
                // discount: checkIn.transaction && checkIn.transaction.discountAmount ? Math.round(checkIn.transaction.discountAmount) : '-',
                'Reverse Transaction Amount': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.amount ? Math.abs(checkIn.transaction.reverseTransactionData.amount) : '-',
                'Reverse Transaction Note': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.notes ? checkIn.transaction.reverseTransactionData.notes : '-',
                'Reverse Transaction Time': checkIn.transaction && checkIn.transaction.reverseTransactionData && checkIn.transaction.reverseTransactionData.createdAt ? moment(checkIn.transaction.reverseTransactionData.createdAt).format('DD-MM-YYYY hh:mm A') : '-',
              }
            ))
            csvExporter.generateCsv(csvData)
          } else {
            openNotification('error', 'This restaurant have 0 checkins in selected time period!')
          }
          setSubmit(false)
        })
      } catch (e) {
        setSubmit(false)
        openNotification('error', ErrorMessages(e))
        console.log(e)
      }
    })
  }

  return (
    networkStatus === 1 || loading ? <Spinner /> :
      error ? <Page404 /> :
        restaurantData ?
          <div className={`restaurant-wrapper ${isRestaurantMember ? 'restaurant-member' : ''}`}>
            <PageHeader
              title={<Title level={3}>{restaurantData.name}</Title>}
              onBack={() => history.push('/restaurants')}
              className="box"
              subTitle={restaurantData.address}
              extra={[
                <Button type="primary" onClick={() => setShowExportModal(true)} >Export Report</Button>,
                <Button onClick={() => history.push(`/restaurants/${restaurantId}/edit`)}>Edit</Button>,
              ]}
            ></PageHeader>
            <ExportModal
              formRef={exportFormRef}
              shoModal={showExportModal}
              isSubmit={isSubmit}
              handleCancel={handleCancel}
              handleCreate={handleExport}
              singleRestaurant={true}
            />
            <div className="newsChat-detailpage-wrapper">
              <Row gutter={24} type="flex" className="news-chat-wrapper">
                <Meta
                  title="Restaurant Details"
                  description="Restaurant Details"
                />
                <Col span={24}>
                  <Tabs defaultActiveKey="1" onChange={null} size="large">
                    <TabPane tab="Details" key="1">
                      <Details canViewDetails={canViewDetails} postTableData={postTableData} restaurantLoad={loading} restaurantData={data.restaurant} />
                    </TabPane>
                    <TabPane tab="Check Ins" key="2">
                      <CheckIns />
                    </TabPane>
                    {
                      canViewDetails &&
                      <TabPane tab="Payments" key="3">
                        <Payments />
                      </TabPane>
                    }
                    {
                      canViewDetails &&
                      <TabPane tab="Users" key="4">
                        <Staff />
                      </TabPane>
                    }
                  </Tabs>
                </Col>
              </Row>
            </div>

          </div>
          :
          <Page404 />
  )
}
