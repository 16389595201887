import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Row, Col, Table, Pagination } from 'antd'
import moment from 'moment'
import { NavLink, withRouter } from 'react-router-dom'
import { GET_CHECKINS, CHECKINS_CONNECTIONS } from '../../checkins/graphql/Queries'
import Meta from '../../../components/Meta'
import '../customer.css'

function Customers(props) {
  const { match: { params: { customerId } } } = props
  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("checkIn_DESC")
  // const [filters, setFilters] = useState({})

  let { data: checkInData, error: checkInError, loading: checkInLoad, networkStatus, fetchMore } = useQuery(GET_CHECKINS, { variables: { first: 10, filters: { user: { id: customerId } }, orderByFilter }, fetchPolicy: 'network-only' })
  const { data: checkInDataCount, error: checkInDataCountError } = useQuery(CHECKINS_CONNECTIONS, { variables: { filters: { user: { id: customerId } }, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !checkInDataCountError ? (checkInDataCount && checkInDataCount.checkInsConnection) ? checkInDataCount.checkInsConnection.aggregate.count : 1 : 1

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  const column = [
    {
      title: 'Restaurant',
      dataIndex: 'restaurant',
      width: '20%',
      render: (restaurant, record) => restaurant ? <NavLink to={`/restaurants/${record.restaurantId}`} className="discussions-subject">{restaurant}</NavLink> : '-'
    },
    {
      title: 'Amount (₹)',
      dataIndex: 'amount',
      width: '10%',
      render: (amount) => amount ? Math.round(amount) : '-'
    },
    {
      title: 'In Time',
      dataIndex: 'checkIn',
      width: '20%',
      render: (time) => time ? moment(time).format('DD/MM/YYYY, hh:mm A') : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      width: '15%',
      render: (duration) => `${parseInt(duration / 60) ? `${parseInt(duration / 60)} hours` : ''}${Math.round(duration % 60)} minutes`
    },
    {
      title: 'Commission (₹)',
      dataIndex: 'commission',
      width: '10%',
      render: (commission, record) => commission ? Math.round(record.amount * commission / 100) : '-'
    },
    // {
    //   title: 'Discount (₹)',
    //   dataIndex: 'discount',
    //   width: '10%',
    //   render: (discount, record) => discount ? Math.round(record.amount * discount / 100) : '-'
    // },
    {
      title: 'Paid At',
      dataIndex: 'createdAt',
      width: '20%',
      render: (createdAt) => createdAt ? moment(createdAt).format('DD/MM/YYYY, hh:mm A') : null,
      sorter: (a, b) => a.age - b.age
    }
  ]
  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  let postTableData = []
  if (!checkInError && checkInData && checkInData.checkIns) {
    postTableData = checkInData.checkIns.map((t, key) => (
      {
        key: key.toString(),
        amount: t.transaction && t.transaction.amount ? t.transaction.amount : null,
        restaurant: `${t.restaurant && t.restaurant.name ? t.restaurant.name : null}, ${t.restaurant && t.restaurant.address ? t.restaurant.address : ''}`,
        restaurantId: t.restaurant.id,
        createdAt: t.transaction && t.transaction.createdAt ? t.transaction.createdAt : null,
        commission: t.restaurant ? t.restaurant.commission : null,
        // discount: t.restaurant ? t.restaurant.discount : null,
        duration: t.duration,
        checkIn: t.checkIn
      }
    ))
  }

  function handlePagination(pageNumber) {
    if (totalCount > checkInData.checkIns.length) {
      setCurrentPageNumber(pageNumber)
      setTableLoading(true)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { checkIns } = fetchMoreResult
          setTableLoading(false)
          return checkIns.length ? { checkIns: [...checkIns] } : prevResult
        }
      })
    }
  }

  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e), current: currentPageNumber
  }

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Customers" description="" />
      <Col span={24}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={networkStatus === 1 || tableLoading || checkInLoad}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </Col>
    </Row>
  )
}

export default withRouter(Customers);
