import React, { useState, useContext } from 'react'
import { Row, Col, Table, Typography, Form, Pagination, Button, Icon, Tooltip, notification, Popconfirm, Switch, Input } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'
import { AppContext } from '../../AppContext'
import client from '../../apollo'
import { RESTAURANT_CONNECTIONS } from './graphql/Queries'
import { GET_RESTAURANTS_LIST } from './graphql/Queries'
import { UPDATE_RESTAURANT } from './graphql/Mutations'
import Spinner from '../../components/loaders/Spinner'
import Meta from '../../components/Meta'
import './restaurants.css'
import noImage from '../../assets/no-image.jpg'

const { Title } = Typography
const EditableContext = React.createContext()

function openNotification(type, message) {
  notification[type]({
    message,
    duration: 3
  })
}

function EditableCell(props) {
  const { dataIndex, title, inputType, record, index, ...restProps } = props
  return (
    <EditableContext.Consumer>
      {(form) => <td {...restProps}> {restProps.children} </td>}
    </EditableContext.Consumer>
  )
}

function EditableTable(props) {

  const [tableLoading, setTableLoading] = useState(false)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [orderByFilter, setOrderByFilter] = useState("name_ASC")
  const [filters, setFilters] = useState({})
  const { data: resData, error: resError, loading: restaurantLoading, networkStatus, fetchMore } = useQuery(GET_RESTAURANTS_LIST, { variables: { filters, orderByFilter, first: 10 }, fetchPolicy: 'network-only' })
  const { data: resDataCount, error: resCountError } = useQuery(RESTAURANT_CONNECTIONS, { variables: { filters, orderByFilter }, fetchPolicy: "network-only" })
  const totalCount = !resCountError ? (resDataCount && resDataCount.restaurantConnection) ? resDataCount.restaurantConnection.aggregate.count : 1 : 1
  const { state } = useContext(AppContext)
  const canPerformAction = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'ADMIN')

  function getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            ref={input => input && input.focus()}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      sorter: (a, b) => a.age - b.age
    }
  }

  function handleChange(pagination, filters, sorter) {
    if (sorter && sorter.field && sorter.order) {
      setCurrentPageNumber(1)
      if (sorter.order === "descend") {
        setOrderByFilter(`${sorter.field}_DESC`)
      } else {
        setOrderByFilter(`${sorter.field}_ASC`)
      }
    }
  }

  function handleSearch(selectedKeys, confirm, dataIndex) {
    setCurrentPageNumber(1)
    confirm()
    let tempFilters = {}
    if (dataIndex === 'name') {
      tempFilters["name_contains"] = selectedKeys[0]
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = selectedKeys[0]
    }
    setFilters({ ...filters, ...tempFilters })
  }

  function handleReset(clearFilters, dataIndex) {
    clearFilters();
    let tempFilters = {}
    if (dataIndex === 'name') {
      tempFilters["name_contains"] = ""
    } else if (dataIndex === 'address') {
      tempFilters["address_contains"] = ""
    }
    setFilters({ ...filters, ...tempFilters })
  }

  const column = [
    {
      title: 'Restaurant Logo',
      dataIndex: 'logo',
      width: '15%',
      render: (logo, index) => <img src={logo || noImage} className='restaurant-profile-image' alt='logo' height='40px' width='40px' />
    },
    {
      title: 'Restaurant Name',
      dataIndex: 'name',
      width: '20%',
      render: (name) => name ? name : '-',
      ...getColumnSearchProps('name')
    },
    {
      title: 'Location',
      dataIndex: 'address',
      width: '20%',
      render: (address) => address ? address : '-',
      ...getColumnSearchProps('address')
    },
    {
      title: 'Commission (%)',
      dataIndex: 'commission',
      width: '10%',
      render: (commission) => commission ? commission : '-',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Discount (%)',
      dataIndex: 'discount',
      width: '10%',
      render: (discount) => discount ? discount : '-',
      sorter: (a, b) => a.age - b.age
    }, {
      title: 'Due Amount (₹)',
      dataIndex: 'dueAmount',
      render: (dueAmount) => dueAmount ? Math.round(dueAmount) : '-',
      width: '10%',
      sorter: (a, b) => a.age - b.age
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '15%',
      render: (text, record) => {
        return (
          <div className="action-icons">
            <Tooltip title="Edit">
              <Icon type="edit" theme="twoTone" onClick={() => props.history.push(`/restaurants/${record.id}/edit`)} />
            </Tooltip>
            &emsp;
            <Tooltip title="Info">
              <Icon type="info-circle" theme="twoTone" onClick={() => props.history.push(`/restaurants/${record.id}`)} />
            </Tooltip>
            &emsp;
            {
              canPerformAction &&
              <Popconfirm
                title={`Are you sure you want to ${!record.isDisabled ? 'deactivate' : 'activate'} this restaurant?`}
                onConfirm={() => handleActive(record.id, record.isDisabled)}
              >
                <Tooltip title={`${!record.isDisabled ? 'Deactivate' : 'Activate'}`}>
                  <Switch size="small" defaultChecked={!record.isDisabled} checked={!record.isDisabled}/*onChange={(e)=>handleActive(record.id, e)}*/ />

                </Tooltip>
              </Popconfirm>
            }
          </div>
        )
      }
    }
  ]

  async function handleActive(id, status) {
    await client.mutate({
      mutation: UPDATE_RESTAURANT,
      variables: { data: { isDisabled: !status }, id: id },
      refetchQueries: [{
        query: GET_RESTAURANTS_LIST
      }]
    })
      .then(async updateUserResult => {
        setTimeout(() => {
          openNotification("success", `Restaurant ${status ? 'Activated' : 'Deactivated'}!`)
          setTableLoading(false)
        }, 500)
      })
      .catch((e) => {
        openNotification("error", "Something went wrong!")
      })
  }

  const components = {
    body: {
      cell: EditableCell,
    },
  }

  const columns = column.map((col) => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    }
  })

  function handlePagination(pageNumber) {
    if (totalCount > resData.restaurants.length) {
      setTableLoading(true)
      setCurrentPageNumber(pageNumber)
      fetchMore({
        variables: {
          skip: (pageNumber - 1) * 10,
          first: 10
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const { restaurants } = fetchMoreResult
          setTableLoading(false)
          return restaurants.length ? { restaurants: [...restaurants] } : prevResult
        }
      })
    }
  }

  let postTableData = []
  if (!resError && resData && resData.restaurants) {
    postTableData = resData.restaurants.map((res, key) => (
      {
        key: key.toString(),
        id: res.id,
        name: res.name,
        address: res.address,
        discount: res.discount,
        logo: res.logo,
        capacity: res.capacity,
        isDisabled: res.isDisabled,
        commission: res.commission,
        restaurantTimings: res.restaurantTimings,
        dueAmount: res.dueAmount,
        amenities: res.Amenity,
        minCheckinPoints: res.minCheckinPoints
        // minOrder: res.minOrder
      }
    ))
  }
  const paginationProps = {
    position: 'bottom', total: totalCount, onChange: (e) => handlePagination(e)
  }

  return (
    networkStatus === 1 ? <div className="callback-wrapper"><Spinner /></div> :

      <EditableContext.Provider value={props.form}>
        <Table
          scroll={{ x: true }}
          bordered
          loading={restaurantLoading || tableLoading}
          components={components}
          dataSource={postTableData}
          columns={columns}
          pagination={false}
          onChange={handleChange}
        />
        <Pagination onChange={handlePagination} current={currentPageNumber} total={totalCount} {...paginationProps} />
      </EditableContext.Provider>
  )
}

const EditableFormTable = withRouter(Form.create()(EditableTable))

export default function Post(props) {
  const { state } = useContext(AppContext)
  const canPerformAction = state && state.currentUser && state.currentUser.role && (state.currentUser.role === 'ADMIN')

  return (
    <Row gutter={24} type="flex" className="news-chat-wrapper">
      <Meta title="Restaurants" description="" />
      <Col span={24}>
        <div className="title-wrapper">
          <Title level={2}>Restaurants</Title>
          {
            canPerformAction &&
            <Button type='primary' size='large' onClick={() => (props.history.push('restaurants/add'))}>Add Restaurant</Button>
          }
        </div>
        <EditableFormTable />
      </Col>
    </Row>
  )
}
