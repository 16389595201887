import React, { Component } from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import history from './history'
import Layout from './layout'
import Logout from './components/Logout'
import Callback from './modules/auth/Callback'
import GuardedRoute from './modules/auth/GuardedRoute'

export default class Routes extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <Switch>
            <Route exact path='/callback' component={Callback} />
            <Route exact path='/logout' component={Logout} />
            <GuardedRoute path='/' name='layout' component={Layout} />
          </Switch>
        </Router>
      </div>
    )
  }
}
