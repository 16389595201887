import gql from 'graphql-tag'

export const RESTAURANT_CONNECTIONS = gql`
query restaurantsConnection($filters: RestaurantWhereInput, $orderByFilter: RestaurantOrderByInput){
  restaurantsConnection(where: $filters, orderBy: $orderByFilter){
    aggregate{
      count
    }
  }
}`;

export const GET_RESTAURANT = gql`
query restaurant($id: ID!){
  restaurant(where:{id: $id}){
    id
    name
    address
    commission
    discount
    city
    state
    capacity
    mapUrl
    restaurantTimings
    logo
    Amenity
    minOrder
    minCheckinPoints
    images
  }
}
`;

export const GET_RESTAURANT_PAYOUTS = gql`
query payouts($restaurantId: ID!){
  payouts(where:{restaurant:{id: $restaurantId}}){
    id
    restaurant{
      id
      name
      address
    }
    amount
    receivedBy
    paidBy
    createdAt
  }
}
`;

export const USER_CONNECTIONS = gql`
query userConnection($restaurantId: ID!){
  usersConnection(where:{ OR: [{ role: ADMIN }, { role: RESTAURANT_STAFF }, { role: RESTAURANT_OWNER }, { role: IKOVERK_STAFF }], restaurant:{id: $restaurantId} }){
    aggregate{
      count
    }
  }
}`;

export const GET_RESTAURANTS_LIST = gql`
query restaurants($first: Int, $skip: Int, $filters: RestaurantWhereInput, $orderByFilter: RestaurantOrderByInput){
  restaurants(where: $filters, first: $first, skip: $skip, orderBy: $orderByFilter){
    id
    name
    address
    mapUrl
    discount
    logo
    capacity
    isDisabled
    commission
    dueAmount
  }
}`;

export const GET_SIGNED_URLS = gql`
query getSignedPutUrls($data: [SignedUrlDataInput]){
  getSignedPutUrls(data: $data) {
    fileNames
    signedUrls
    getUrls
  }
}
`;

export const GET_RESTAURANT_CALCULATION = gql`
query restaurantCalculation($id: ID!){
  restaurantCalculation(where:{id: $id})
}
`;